import { useContext } from "react";
import { BACKEND_URL } from "../../lib/config";
import { IconTextButton } from "../Button/Button";
import Card from "../Card/Card";
import { Subtitle } from "../Content/Content";
import { IconTextPair } from "../Icon/Icon";
import Popup from "../Popup/Popup";
import { TabsViewer } from "../Viewer/Viewer";
import DeckCard from "./DeckCard";
import styles from "./DeckCardDetails.module.css";
import { AppContext } from "../AppContext";

export function DeckCardDetails({ card, noShare, ...props }){

    const appStates = useContext(AppContext);

    function copyDetails(){
        navigator.clipboard.writeText(`https://eapplico.com/share-card/${card?.Oid}`);
        appStates.addSideNotification("Link copied to clipboard!");
    }
    function deviceShare(){
        navigator.share({
            title: "Eapplico Card",
            text: "Check out my Eapplico Card",
            url: `https://eapplico.com/share-card/${card?.Oid}`
        });
    }
    
    const tabs = [];

    if(!noShare){
        tabs.push(
            { name: "Share", content: <>
                <Card style={{ marginBottom: 15, paddingBottom: 25 }}>
                    <Subtitle>Share via QR Code</Subtitle>
                    <img 
                        className={ styles.qrCode }
                        src={ `${BACKEND_URL}card/qrcode/${card?.Oid}` } 
                    />
                </Card>
                <Card>
                    <Subtitle>Share via Link</Subtitle>
                    <IconTextButton 
                        onClick={ copyDetails }
                        iconName={ `file-copy-line` } 
                    >
                        Copy Link to Clipboard 
                    </IconTextButton>
                    <IconTextButton 
                        onClick={ deviceShare }
                        iconName={ `share-2-line` } 
                    >
                        Share to Other Apps
                    </IconTextButton>
                </Card>
                <div style={{ width: "100%", height: 30 }}></div>
            </> 
        })
    }

    tabs.push(
        { name: "Details", content: <div className={ styles.tabsViewerContent }>
            <IconTextPair 
                iconName="user-3-line"
                style={{ fontSize: 20 }} 
            >
                { card?.FirstName } { card?.MiddleName } { card?.LastName }
            </IconTextPair>
            { card?.Position ? 
                <IconTextPair 
                    iconName="passport-line"
                    style={{ fontSize: 20 }} 
                >
                    { card?.Position }
                </IconTextPair>
            : null }
            { card?.Headline ? 
                <IconTextPair 
                    iconName="open-arm-line"
                    style={{ fontSize: 20 }} 
                >
                    { card?.Headline }
                </IconTextPair>
            : null }
            { card?.PublicEmail ? 
                <IconTextPair 
                    iconName="mail-line"
                    style={{ fontSize: 20 }} 
                >
                    { card?.PublicEmail }
                </IconTextPair>
            : null }
            { card?.Phone ? 
                <IconTextPair 
                    iconName="phone-line"
                    style={{ fontSize: 20 }} 
                >
                    { card?.Phone }
                </IconTextPair>
            : null }
            { card?.Address ? 
                <IconTextPair 
                    iconName="map-pin-line"
                    style={{ fontSize: 20 }} 
                >
                    { card?.Address }
                </IconTextPair>
            : null }
        </div> 
    })

    return (
        <>
            <DeckCard 
                style={{ margin: "20px auto" }}
                card={ card } 
            />
            <TabsViewer tabs={ tabs } />
        </>
    )
}

export default function DeckCardDetailsPopup({ card, noShare, ...props }){

    return (
        <Popup 
            title="Card Details"
            size="slim"
        {...props}>
            <DeckCardDetails 
                card={ card } 
                noShare={ noShare } 
            />
        </Popup>
    )
}