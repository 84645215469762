import { useEffect, useState } from "react";
import styles from "./DeckScheme.module.css";
import Icon from "../Icon/Icon";
import { IconButton, IconTextButton } from "../Button/Button";

export default function DeckScheme({ folderDataProps, onSelect, ...props }){
    const [folderData, setFolderData] = useState(folderDataProps);
    useEffect(() => {
        setFolderData(curr => {
            return {
                ...folderDataProps,
                ...curr
            }
        })
    }, [folderDataProps]);

    function handleFolderClick(folderId){
        if(selectedDeck === folderId){
            setSelectedDeck(null);
        }else{
            setSelectedDeck(folderId);
        }
    }

    const [currentFolderId, setCurrentFolderId] = useState("ROOT");
    const [selectedDeck, setSelectedDeck] = useState(null);

    function blankClicked(e){
        if(e.target === e.currentTarget){
            setSelectedDeck(null);
        }
    }

    function moveClicked(){
        if(!selectedDeck) return;
        onSelect && onSelect(selectedDeck);
    }

    return (
        <div className={ styles.deckScheme }>
            <div className={ styles.deckSchemeTop }>
                <div className={ styles.deckSchemeTopLeft }>
                    Select a deck to move into
                </div>
                <div className={ styles.deckSchemeTopRight }>
                    <IconTextButton primary
                        style={{ height: 50, borderRadius: 0,
                            filter: selectedDeck ? "none" : "grayscale(100%)",
                        }}
                        iconName={ "folder-shared-line" }
                        onClick={ moveClicked }
                    >
                        Move
                    </IconTextButton>
                </div>
            </div>
            <div className={ styles.deckSchemeContent } onClick={ blankClicked }>
                <DeckSchemeItem
                    style={{ marginTop: 20 }}
                    indent={0}
                    selected={selectedDeck === "ROOT"}
                    onClick={() => handleFolderClick("ROOT")}
                >
                    All Decks (Root Level)
                </DeckSchemeItem>
                { folderData[currentFolderId]?.subfolders?.map((folder, index) => {
                    return (
                        <DeckSchemeItem
                            key={ index }
                            indent={1}
                            selected={selectedDeck === folder.Oid}
                            onClick={() => handleFolderClick(folder.Oid)}
                        >
                            { folder.Name }
                        </DeckSchemeItem>
                    )
                } )}
            </div>
            
        </div>
    )

}

export function DeckSchemeItem({ children, selected, indent, ...props }){
    return (
        <div 
            className={ `
                ${styles.deckSchemeItem}
                ${selected ? styles.deckSchemeItemSelected : ""}
            ` }
            style={{ marginLeft: `${indent * 20}px` }}
            {...props}
        >
            <div className={ styles.deckSchemeItemIcon }>
                { selected ? 
                    <Icon 
                        iconName={ "checkbox-circle-fill" } 
                        style={{ transform: "translateY(12px)", display: "block", color: "var(--theme-color)" }} 
                    />
                : 
                    <Icon 
                        iconName={ "stack-line" } 
                        style={{ transform: "translateY(12px)", display: "block" }} 
                    />
                }
            </div>
            <div className={ styles.deckSchemeItemName }>            
                { children }
            </div>
        </div>
    )
}