import { useContext, useState } from "react"
import { Button } from "../Button/Button"
import Card from "../Card/Card"
import { FormLabel, Input } from "../Form/Form"
import Popup from "../Popup/Popup"
import { ProgressViewer } from "../Viewer/Viewer"
import { BACKEND_URL } from "../../lib/config"
import { AppContext } from "../AppContext"
import { DeckCardDetails } from "../Deck/DeckCardDetails"
import { fetchBackend } from "../../lib/util"
import { Content } from "../Content/Content"
import ShareCard from "../ShareCard/ShareCard"

export default function QRAndLink({ onComplete }){
    const appStates = useContext(AppContext);

    const [currentStep, setCurrentStep] = useState(0);
    const [linkValue, setLinkValue] = useState("");
    const [card, setCard] = useState(null);

    function uploadDecodeContent(){
        const formData = new FormData();
        formData.append("image", document.getElementById("qr-code-upload").files[0]);
        formData.append("link", linkValue);
        formData.append("token", localStorage.getItem("userToken"));
        fetch(`${BACKEND_URL}card/decodeShareContent`, {
            method: "POST",
            body: formData
        })
        .then(res => res.json())
        .then(data => {
            if(data.error){
                if(data.error === "CardOid.self"){
                    appStates.addSideNotification("You cannot add a card of yourself.");
                    return;
                }
                appStates.addSideNotification("Error decoding the QR Code or Link. Please try again with a valid one.");
                return;
            }
            setCard(data?.card);
            setCurrentStep(1);
        })
    }

    function addCardToMyDeck(){
        fetchBackend("POST", "card/connect", {
            CardOid: card.Oid
        })
        .then(data => {
            if(data.error){
                appStates.addSideNotification("Error adding card to MyDeck. Please try again.");
                return;
            }
            setCurrentStep(2);
            if(onComplete) onComplete();
        })
    }

    return (
        <>
            <ProgressViewer currentStep={ currentStep } steps={[ 
                { name: "1. Use Link or QR Code", content: <>
                    <Card style={{ paddingBottom: 30, marginBottom: 25 }}>
                        <Input
                            id="qr-code-upload"
                            style={{ marginBottom: 25, backgroundColor: "white" }}
                            label="Upload QR Code Image" 
                            type="file" 
                        />
                        <Button onClick={ uploadDecodeContent }>
                            Confirm Upload
                        </Button>
                    </Card>
                    <Card style={{ paddingBottom: 30,  }} >
                        <Input
                            defaultValue={ linkValue }
                            onChange={ e => setLinkValue(e.target.value) }
                            style={{ marginBottom: 25, backgroundColor: "white" }}
                            label="Use Link" 
                            type="text" 
                        />
                        <Button onClick={ uploadDecodeContent }>
                            Confirm Upload
                        </Button>
                    </Card>
                </> },
                { name: "2. Confirm Card", content: <>
                    <DeckCardDetails
                        card={ card }
                        noShare
                    />
                    <div style={{ width: "100%", height: 80 }}></div>
                    <Button style={{  borderTopLeftRadius: 0, borderTopRightRadius: 0,
                        position: "absolute", bottom: 0, left: -15, width: "calc(100% + 30px)" }}
                        onClick={ addCardToMyDeck }
                    >
                        Add to MyDeck
                    </Button>
                </> },
                { name: "Send Them Yours as Well?", content: <>
                    <Card>
                        <Content>
                            That card has been added to your deck. You can choose to <b>share your card with them</b> as well. <b>Pick one below:</b> 
                        </Content>
                    </Card>
                    <FormLabel style={{ margin: 5 }}>
                        Click on a card to share 
                    </FormLabel>
                    <ShareCard
                        onComplete={ () => setCurrentStep(3) }
                        targetUserOid={ card?.UserOid }
                    />
                </>},
                { name: "Complete!", content: <>
                    <Card>
                        <Content>
                            You have added their card to your MyDeck, and also shared your card with them! Now you can close this popup window.
                        </Content>
                    </Card>
                </> }
             ]} />
        </>
    )
}

export function QRAndLinkPopup({ onComplete, ...props }){
    return (
        <Popup
            title="From QR Code or Link"
            { ...props }
        >
            <QRAndLink onComplete={ onComplete } />
        </Popup>
    )
}