import React, { createContext, useContext} from "react";
import LoginCss from "./Login.module.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { fetchBackend } from "../../lib/util";
import { Button } from "../../components/Button/Button";
import { useTitle } from "../../lib/hooks";
import { Input } from "../../components/Form/Form";
import Radiation from "../../components/Artistic/Radiation";
import { AppContext } from "../../components/AppContext";
// import {context} from "../../App.js"

function Login ({  }) {
  const appStates = useContext(AppContext);
  let navigate = useNavigate();
  useTitle("Login");

  const submitData = (loginData) => {
    fetchBackend("POST", `user/login/${loginData.get("email")}`, {
      "Password": loginData.get("password"),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Data from backend', data, data.error);
        if(data.error){
          appStates.addSideNotification("Incorrect email or password. Please try again.");
        }
        else{
            localStorage.setItem('userToken', data["token"]);
            // Force reload of the entire app
            window.location.href = "/";
        }
    });
  };

  const catchSubmit = (event) => {
    event.preventDefault();
    const loginData = new FormData(event.currentTarget);
    console.log({
      username: loginData.get("email"),
      password: loginData.get("password"),
    });
    submitData(loginData);
  };
  return (
    <>
      <Radiation size={ 2000 } />
      <div className={`${LoginCss.loginBox} glassmorphic`}>
        <div className={LoginCss.loginTitle}>Eapplico Login</div>
        <form className={LoginCss.loginForm} onSubmit={catchSubmit}>
          <Input
            label="User Email"
            type="email"
            id="email"
            name="email"
            placeholder="name@example.com"
            style={{ width: "100%", marginBottom: 25 }}
          />
          <Input
            label="User Password"
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            style={{ width: "100%", marginBottom: 25 }}
          />
          <div className={LoginCss.loginButtonContainer}>
            <Button style={{ width: "100%", marginTop: 20 }}>
              Login
            </Button>
          </div>

          <div className={ LoginCss.loginHintText }>
            Don't have an account? <a href="/account/signup">Signup!</a> It's free!
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
