import { DeckCardExample1, DeckCardExample2 } from "../Deck/DeckCard";
import Icon from "../Icon/Icon";
import styles from "./SearchDemo.module.css";

export default function SearchDemo({ ...props }){
    return (
        <div className={ styles.searchDemoBase } {...props}>
            <div className={ styles.searchDemoMain }>
                <div className={ styles.searchDemoBoxOuter }>
                    <div className={ styles.searchDemoBox }>
                        pink business card, man in tech, met at a conference in New York
                    </div>
                    <div className={ styles.searchDemoBoxRight }>
                        <Icon iconName={ "search-line" } style={{ display: "block", margin: "27px 13px", color: "white", fontSize: 30 }} />
                    </div>
                </div>
                <div className={ styles.searchDemoResults }>
                    <DeckCardExample1 style={{ margin: "20px auto" }} />
                    <DeckCardExample2 style={{ margin: "20px auto" }} />
                    <div className={ styles.searchDemoResultsOverlay }></div>
                </div>
            </div>
        </div>
    )
}