import { useContext, useState } from "react";
import Popup from "../Popup/Popup";
import { ProgressViewer } from "../Viewer/Viewer";
import { FormLabel, Input } from "../Form/Form";
import { Button, RoundedButton } from "../Button/Button";
import Card from "../Card/Card";
import SearchDemo from "../Artistic/SearchDemo";
import styles from "./NewCard.module.css";
import { Content } from "../Content/Content";
import WebCam from "react-webcam";
import { fetchBackend } from "../../lib/util";
import { AppContext } from "../AppContext";
import { BACKEND_URL } from "../../lib/config";
import PerspectiveCropPopup from "../PerspectiveCrop/PerspectiveCropPopup";

export function NewCardPopup({ onComplete, currentFolderId, owned, ...props }){

    const style = {
        overflow: "unset",
        ...props.style,
    }

    return (
        <Popup 
            title="Scan New Card"
            {...props}
            style={style}
        >
            <NewCard owned={ owned }
                currentFolderId={ currentFolderId }
                onComplete={ onComplete } 
            />
        </Popup>
    )
}

export default function NewCard({ onComplete, currentFolderId, owned, ...props }){
    const appStates = useContext(AppContext);

    const [currentStep, setCurrentStep] = useState(0);
    const [cardData, setCardData] = useState(null);
    const [initialCardImageUrl, setInitialCardImageUrl] = useState(null);
    const [finalCardImageURL, setFinalCardImageURL] = useState(null);
    const [finalCardImage, setFinalCardImage] = useState(null);
    const [croppedImageLoading, setCroppedImageLoading] = useState(false);

    function getCroppedImage(file){
        const formData = new FormData();
        setCroppedImageLoading(true);
        formData.append("image", file);
        // formData.append("top_left", JSON.stringify({ x: 0, y: 0 }));
        // formData.append("bot_right", JSON.stringify({ x: 1, y: 1 }));
        formData.append("top_left", JSON.stringify({ x: 0, y: 0 }));
        formData.append("bot_right", JSON.stringify({ x: 1, y: 1 }));
        fetch("https://businesscardvision.uc.r.appspot.com/api/processImage", {
            method: "POST",
            body: formData
        })
        .then(response => response.blob())
        .then(blob => {
            const file = new File([blob], "image.jpg", { type: "image/jpeg" });
            setFinalCardImageURL(URL.createObjectURL(file));
            setFinalCardImage(file);
            setCroppedImageLoading(false);
        })
    }

    function uploadImage(){
        const file = document.querySelector("#card-upload").files[0];
        if(!file) return;
        setCurrentStep(1);
        getCroppedImage(file);
        console.log(file);
        setInitialCardImageUrl(URL.createObjectURL(file));
        const formData = new FormData();
        formData.append("image", file);
        fetch("https://businesscardvision.uc.r.appspot.com/api/uploadImage", {
            method: "POST",
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            setCardData(data);
            setCurrentStep(2);
        })
    }

    function captureAndUpload(getScreenshot){
        const src = getScreenshot();
        setCurrentStep(1);
        getCroppedImage(file);
        fetch(src)
        .then(response => response.blob())
        .then(blob => {
            const file = new File([blob], "image.jpg", { type: "image/jpeg" });
            const formData = new FormData();
            formData.append("image", file);
            fetch("https://businesscardvision.uc.r.appspot.com/api/uploadImage", {
                method: "POST",
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                setCardData(data);
                setCurrentStep(2);
            })
        });
    }

    function cardDataChanged(fieldName, value){
        setCardData(curr => {
            return {
                ...curr,
                [fieldName]: value
            }
        })
    }

    function saveCardData(){
        setCurrentStep(3);
        const cardObject = Object.assign({}, cardData);
        cardObject.ParentOid = currentFolderId;
        fetchBackend("POST", `card/${owned ? "create" : "save"}`, {
            card: cardObject,
        })
        .then(data => data.json())
        .then(data => {
            if(data.error) {
                appStates.addSideNotification("Error creating card. Please try again later.");
                return;
            }
            const imageFormData = new FormData();
            imageFormData.append("image", finalCardImage);
            imageFormData.append("CardOid", data.card.Oid);
            imageFormData.append("token", localStorage.getItem("userToken"));
            fetch(`${BACKEND_URL}card/addImage`, {
                method: "POST",
                body: imageFormData,
            })
            .then(data => data.json())
            .then(data => {
                if(data.error) {
                    appStates.addSideNotification("Error uploading card image. Please try again later.");
                    return;
                }
                appStates.addSideNotification("Card created successfully!");
                setCurrentStep(4);
                if(onComplete) onComplete();
            })
        })
    }

    const [showingCropPopup, setShowingCropPopup] = useState(false);

    return (
        <>
            <PerspectiveCropPopup
                baseProps={{ style: { zIndex: 1000 } }}
                showing={ showingCropPopup }
                toggleFunction={ setShowingCropPopup }
                originalImage={ initialCardImageUrl }
            />
            <ProgressViewer currentStep={ currentStep } steps={[
                { name: "1. Upload Image to Analyze", content: <>
                    <Card style={{ paddingBottom: 30, marginBottom: 25 }}>
                        <Input 
                            id="card-upload"
                            style={{ marginBottom: 25, backgroundColor: "white" }}
                            label="Use Existing Image" 
                            type="file" 
                        />
                        <Button onClick={ uploadImage }>
                            Confirm Upload
                        </Button>
                    </Card>
                    <Card style={{ marginBottom: 25, paddingBottom: 110 }}>
                        <FormLabel>Or Use Webcam</FormLabel>
                        <div className={ styles.webcamWrapper } >
                            <div className={ styles.webcamWrapperHint }>
                                Web cam is loading... Please allow access to your camera.
                            </div>
                            <WebCam
                                audio={false}
                                screenshotFormat="image/jpeg"
                                style={{ height: "200px", position: "relative", zIndex: 9 }}
                            >
                                { ({ getScreenshot }) => {
                                    return (
                                        <Button
                                            onClick={ () => captureAndUpload(getScreenshot) }
                                            style={{ position: "absolute", left: 0, top: 225 }}
                                        >
                                            Capture Image
                                        </Button>
                                    )
                                } }
                            </WebCam>
                        </div>
                    </Card>
                </> },
                { name: "2. Analyzing Image...", content: <>
                    <div className={ styles.analysisGraphics }>
                        <SearchDemo style={{ marginTop: 30 }} />
                    </div> 
                </> },
                { name: "3. Check Information", content: <>
                    <div className={ styles.checkCardDisplay }
                        style={{ backgroundImage: `url(${finalCardImageURL})` }}
                    >
                        { croppedImageLoading ? <div className={ styles.loadingOverlay }>
                            <div className={ styles.loadingOverlayText }>
                                Processing Card Image...
                            </div>
                        </div> : <div className={ styles.editOverlay } >
                            <RoundedButton
                                onClick={ () => setShowingCropPopup(true) } 
                                style={{ backgroundColor: "white", position: "absolute", 
                                    bottom: 20, left: 20, boxShadow: "0 0 20px 0 rgba(0,0,0,.3)" }} 
                                iconName="crop-line"
                            >
                                Edit Image Cropping
                            </RoundedButton>
                        </div> }
                    </div>

                    <Card style={{ margin: "25px 0" }}>
                        <Content>If you see anything wrong with the extracted content, edit the text fields. Then click <b>Save Card</b>.</Content>
                    </Card>
                    <Input 
                        label="First Name" 
                        onChange={ e => cardDataChanged("FirstName", e.target.value) }
                        defaultValue={ cardData?.FirstName }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Middle Name" 
                        onChange={ e => cardDataChanged("MiddleName", e.target.value) }
                        defaultValue={ cardData?.MiddleName }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Last Name" 
                        onChange={ e => cardDataChanged("LastName", e.target.value) }
                        defaultValue={ cardData?.LastName }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Public Email" 
                        onChange={ e => cardDataChanged("PublicEmail", e.target.value) }
                        defaultValue={ cardData?.PublicEmail }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Phone Number" 
                        onChange={ e => cardDataChanged("Phone", e.target.value) }
                        defaultValue={ cardData?.Phone }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Company" 
                        onChange={ e => cardDataChanged("Company", e.target.value) }
                        defaultValue={ cardData?.Company }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Position" 
                        onChange={ e => cardDataChanged("Position", e.target.value) }
                        defaultValue={ cardData?.Position }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Headline" 
                        onChange={ e => cardDataChanged("Headline", e.target.value) }
                        defaultValue={ cardData?.Headline }
                        style={{ marginBottom: 15 }} 
                    />
                    <Input 
                        label="Address" 
                        onChange={ e => cardDataChanged("Address", e.target.value) }
                        defaultValue={ cardData?.Address }
                        style={{ marginBottom: 25 }} 
                    />
                    <Button onClick={ saveCardData }>
                        Save Card
                    </Button>
                    <div style={{ width: "100%", height: "50px" }}></div>
                </> },
                { name: "Uploading Data...", content: <>
                    <div className={ styles.analysisGraphics }>
                        <SearchDemo style={{ marginTop: 30 }} />
                    </div>
                </>},
                { name: "Completed Adding!", content: <>
                    <Card>
                        <Content>
                            Your card has been added to your deck. You can now close this popup window and view it in your deck.
                        </Content>
                    </Card>
                </>}
            ]} />
        </>
    )
}