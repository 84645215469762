import styles from "./Radiation.module.css"

export default function Radiation({ size, ...props }){

    const propStyles = {
        width: size,
        height: size,
        marginBottom: -size,
        ...props.style
    }

    return (
        <div className={ styles.radiation } {...props} style={ propStyles }>
            <div className={ styles.radiationOverlay }></div>
        </div>
    )
}