import styles from "./Flex.module.css"

export default function Flex({ children, gap, ...props }){
    const styleProp = {
        gap: gap,
        ...props.style
    }
    return <div className={ styles.flex } {...props} style={ styleProp } >
        { children }
    </div>
}