import React, { useState, createContext, useContext} from "react";
import HomeCss from "./Home.module.css";
import { useTitle } from "../../lib/hooks";
import { AppContext } from "../../components/AppContext";
import { Title } from "../../components/Content/Content";
import { CenterViewer } from "../../components/Viewer/Viewer";
import Construction from "../../components/Construction/Construction";
import { useNavigate } from "react-router-dom";
  
function Home(setSelected) {
  const navigate = useNavigate();
  navigate("/mydeck");

  const appStates = useContext(AppContext);
  // useTitle("Home");
  useTitle("MyDeck");

  return(
    <>
      {/* <CenterViewer>
        <Construction />
        <hr />
        <Title>Welcome Back, {appStates.user?.FirstName} </Title>
      </CenterViewer> */}
    </>
  );
};

export default Home;