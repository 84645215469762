import { useId } from "react"
import styles from "./LogoBand.module.css"

export default function LogoBand({ logos, maxWidth, footnote, grayscale, ...props }){

    const id = useId();

    return (
        <div className={ `${styles.logoBand}` } {...props}>
            <div className={ styles.logoBandGrid } style={{ maxWidth }}>
                { logos.map(logo => {
                    return (
                        <img 
                            key={ `logo-band-${id}-${logo.src}` }
                            className={ styles.logoBandItem }
                            alt={ logo.alt }
                            src={ logo.src } 
                            style={{ width: logo.size, filter: grayscale ? "grayscale(1)" : "" }} />
                        )
                }) }
            </div>
            <div className={ styles.logoBandFootnote }>
                { footnote }
            </div>
        </div>
    )
}