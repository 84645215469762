import { useState } from "react";
import Avatar from "../Avatar/Avatar";
import styles from "./Connection.module.css";
import ConnectionPopup from "./ConnectionPopup";

export function PendingConnectionItem({ connection, user, ...props }){

    return (
        <>
            <div className={ styles.connectionHorizontalItem } {...props}>
                <Avatar 
                    username={ user?.Username }
                    size={ 128 } 
                    style={{ borderBottomLeftRadius: 0, 
                        borderBottomRightRadius: 0, border: "none" }} 
                />
                <div className={ styles.connectionHorizontalItemName }>
                    { `${user?.FirstName ?? ""} ${user?.LastName ?? ""}` }
                </div>
                <div className={ styles.connectionHorizontalItemDescription }>
                    { user?.Company ?? "" }
                </div>
                {/* <Button 
                    style={{ width: "calc(100% + 2px)", height: 40, lineHeight: "40px", 
                        padding: 0, marginLeft: -1, fontSize: `calc(var(--font-size)/7)`, 
                        borderTopLeftRadius: 0, borderTopRightRadius: 0,  }}>
                    View
                </Button> */}
            </div>
        </>
    )
}

export function PendingConnectionList({ connections, usersMap, cardsMap, reloadConnections, ...props }){

    const [selectedConnection, setSelectedConnection] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showingPopup, setShowingPopup] = useState(false);
    function viewConnection(connection, user, card){
        setSelectedConnection(connection);
        setSelectedUser(user);
        setSelectedCard(card);
        setShowingPopup(true);
    }

    return (
        <>
            <ConnectionPopup
                reloadConnections={ reloadConnections }
                showing={ showingPopup }
                toggleFunction={ setShowingPopup }
                request={ selectedConnection }
                user={ selectedUser }
                card={ selectedCard }
            />
            <div className={ styles.connectionListHorizontal } {...props}>
                <div className={ styles.connectionListHorizontalWrap }>
                    { (connections ?? []).map((connection, index) => {
                        return (
                            <PendingConnectionItem
                                key={ `connection-pending-${index}` }
                                connection={ connection }
                                user={ usersMap?.[connection.UserOid] }
                                onClick={ () => viewConnection(
                                    connection, 
                                    usersMap?.[connection.UserOid],
                                    cardsMap?.[connection.CardOid]
                                ) }
                            />
                        )
                    }) }
                </div>
            </div>
        </>
    )
}

export function ConnectionItem({ connection, ...props }){
    return (
        <div className={ styles.connectionVerticalItem } {...props}>
            <Avatar size={ 100 } username={ connection.Username } style={{  }} />
            <div className={ styles.connectionVerticalItemRight }>
                <div className={ styles.connectionVerticalItemName }>
                    { `${connection?.FirstName ?? ""} ${connection?.LastName ?? ""}` }
                </div>
                <div className={ styles.connectionVerticalItemDescription }>
                    { connection?.Position ? connection.Position : "Unknown Position" }
                </div>
                <div className={ styles.connectionVerticalItemDescription }>
                    { connection?.Company ? connection.Company : "Unknown Company" }
                </div>
            </div>
        </div> 
    )
}

export function ConnectionList({ connections, ...props }){

    const [selectedConnection, setSelectedConnection] = useState(null);
    const [showingPopup, setShowingPopup] = useState(false);
    function viewConnection(connection){
        setSelectedConnection(connection);
        setShowingPopup(true);
    }
    
    return (
        <>
            <ConnectionPopup
                showing={ showingPopup }
                toggleFunction={ setShowingPopup }
                connection={ selectedConnection }
                user={ selectedConnection }
                // Currently this only displays the first card.
                card={ selectedConnection?.Cards[0] }
                displayCard={ false }
                tab={ 1 }
            />
            <div className={ styles.connectionListVertical } {...props}>
                { (connections ?? []).map((connection, index) => {
                    return (
                        <>
                            <ConnectionItem
                                key={ `connection-vertical-${index}` }
                                connection={ connection }
                                onClick={ () => viewConnection(connection) }
                            />
                        </>
                    )
                }) }
            </div>
        </>
    )
}