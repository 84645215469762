import styles from "./Notification.module.css";

export function NotificationItem({ ...props }){
    return (
        <div className={ styles.notificationItem } {...props}>
            
        </div>
    )
}

export function NotificationList({ ...props }){
    return (
        <div className={ styles.notificationList } {...props}>
            <NotificationItem />
            <NotificationItem />
            <NotificationItem />
            <NotificationItem />
        </div>
    )
}