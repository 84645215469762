import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import styles from "./TopBar.module.css";
import { Button, IconButton } from "../Button/Button";
import LoginOnly from "../LoginOnly/LoginOnly";
function TopBar({logText, setlogText}) {
  
  let navigate = useNavigate();
  const [selected, setSelected] = useState("home");

  useEffect(() => {
    const pathname = window.location.pathname; 
    if(pathname.includes("home") || pathname === "/"){
      setSelected("home");
    }else if(pathname.includes("connections")){
      setSelected("connections");
    }else if(pathname.includes("notifications")){
      setSelected("notifications");
    }else if(pathname.includes("account")){
      setSelected("account");
    }else if(pathname.includes("mydeck")){
      setSelected("mydeck");
    }
  }, [window.location.pathname]);
  
  return (
    <div className={styles.topbar}>
      <div className={styles.left}>
        <button
          className={styles.eapplico}
          onClick={() => {
            navigate("/mydeck");
          }}
          style={{ backgroundImage: `url(/img/branding/logo-full-black.svg)` }}
        >
          
        </button>
      </div>
      <div className={styles.middle}>

      </div>
      <div className={styles.right}>
        <LoginOnly componentMode 
          fallback={
            <Button 
              onClick={ () => window.location.href="/account/login" }
              style={{ height: 50, borderRadius: 0, lineHeight: 0.8 }}
            >
              Login
            </Button>
          }
        >
          {/* <IconButton 
            selected={selected === "home"}
            iconName="home-line" 
            onClick={() => {
              navigate("/home");
            }}
          /> */}
          <IconButton 
            selected={selected === "mydeck"}
            iconName="stack-line" 
            onClick={() => {
              navigate("/mydeck");
            }}
          />
          <IconButton 
            selected={selected === "connections"}
            iconName="group-line" 
            onClick={() => {
              navigate("/connections");
            }}
          />
          <IconButton 
            selected={selected === "notifications"}
            iconName="notification-line"
            onClick={() => {
              navigate("/notifications");
            }}
          />
          <IconButton 
            selected={selected === "account"}
            iconName="user-line" 
            onClick={() => {
              navigate("/account");
            }}
          />
        </LoginOnly>
      </div>
    </div>
  );
};

export default TopBar;
