import Icon from "../Icon/Icon"
import styles from "./Button.module.css"

export function Button({ children, ...props }) {
    return <>
        <button 
            className={styles.button}
        {...props}>
            {children}
        </button>
    </>
}

export function IconButton({ children, iconName, selected, ...props }){
    return <button
        className={`${styles.iconButton} ${selected ? styles.iconButtonSelected : ""}`}
    {...props}>
        <Icon iconName={iconName} />
        { children && <div>
            {children}
        </div> }
    </button>
}

export function IconTextButton({ children, iconName, primary, ...props }){
    return <button 
        className={ `${styles.iconTextButton} ${primary ? styles.iconTextButtonPrimary : ""}` }
        {...props}
    >
        <Icon style={{ fontSize: 20 }} iconName={iconName} />
        <div>
            &nbsp;{ children }
        </div>
    </button>
}

export function IconTextButtonStack({ children, ...props }){
    return <div className={ styles.iconTextButtonStack } {...props}>
        { children }
    </div>
}

export function AppStoreButton({ ...props }){
    return (
        <button className={ styles.appStoreButton } {...props}>
            <div className={ styles.appStoreButtonContent } style={{ backgroundImage: `url(/img/external/appstore.svg)` }}>
            </div>
        </button>
    )
}

export function ForwardButton({ children, ...props }){
    return (
        <button className={ styles.forwardButton } {...props}>
            <div className={ styles.forwardButtonLeft }>
                { children }
            </div>
            <div className={ styles.forwardButtonRight }>
                <Icon iconName="arrow-right-s-line" />
            </div>
        </button>
    )
}

export function RoundedButton({ children, ...props }){

    const style = {
        borderRadius: 100,
        padding: "10px 20px",
        ...props.style
    }

    return (
        <IconTextButton {...props} style={ style }>
            { children }
        </IconTextButton>
    )
}