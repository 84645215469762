import Icon from "../Icon/Icon";
import styles from "./FloatingChips.module.css";

export default function FloatingChips({...props}){
    return (
        <div className={ styles.floatingChipsContainer } {...props}>
            <FloatingChip iconName={ "facebook-circle-line" }
                style={{ transform: "translateX(-10px) translateY(10px)" }}
            >
                @adam.smith.r
            </FloatingChip>
            <FloatingChip iconName={ "phone-line" }
                style={{ transform: "translateX(180px) translateY(10px)" }}
            >
                984-373-1006
            </FloatingChip>
            <FloatingChip iconName={ "user-line" }
                style={{ transform: "translateX(-50px) translateY(30px)" }}
            >
                Adam Smith
            </FloatingChip>
            <FloatingChip iconName={ "mail-line" }
                style={{ transform: "translateX(150px) translateY(20px)" }}
            >
                hi@uyz.com
            </FloatingChip>
            <FloatingChip iconName={ "linkedin-box-line" }
                style={{ transform: "translateX(340px) translateY(-10px)" }}
            >
                in/adam-smith
            </FloatingChip>
            <FloatingChip iconName={ "user-line" }
                style={{ transform: "translateX(-40px) translateY(30px)" }}
            >
                Adam R. Smith
            </FloatingChip>
            <FloatingChip iconName={ "mail-line" }
                style={{ transform: "translateX(190px) translateY(10px)" }}
            >
                ar.smith@eapplico.com
            </FloatingChip>
            <FloatingChip iconName={ "phone-line" }
                style={{ transform: "translateX(440px) translateY(-160px)" }}
            >
                984-006-3570
            </FloatingChip>
            <FloatingChip iconName={ "user-line" }
                style={{ transform: "translateX(460px) translateY(-60px)" }}
            >
                Dr. Smith
            </FloatingChip>
            <FloatingChip iconName={ "instagram-line" }
                style={{ transform: "translateX(70px) translateY(-10px)" }}
            >
                adam.smith
            </FloatingChip>
            {/* <FloatingChip iconName={ "mail-line" }>hi@uyz.com</FloatingChip> */}
            {/* <FloatingChip iconName={ "mail-line" }>hi@uyz.com</FloatingChip> */}
        </div>
    )
}

export function FloatingChip({ iconName, children, ...props }){
    return (
        <div className={ styles.floatingChip } {...props}>
            <div className={ styles.floatingChipLeft }>
                <Icon iconName={ iconName } />
            </div>
            <div className={ styles.floatingChipRight }>
                { children }
            </div>
        </div>
    )
}