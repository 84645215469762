import { useEffect, useState } from "react";
import { MarkdownParser } from "../../components/Parser/Parser";
import { CenterViewer } from "../../components/Viewer/Viewer";
import { useTitle } from "../../lib/hooks"
import { getGitHubSource } from "../../lib/util";

export default function Markdown({ src, title, ...props }){

    useTitle(title);

    const [content, setContent] = useState("");
    useEffect(() => {
        getGitHubSource("eapplico", "public-content", src, "main", setContent);
    })

    return (
        <>
            <CenterViewer style={{ maxWidth: 800 }}>
                <MarkdownParser content={ content } />
            </CenterViewer>
        </>
    )
}