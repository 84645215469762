import { useState } from "react"
import { BACKEND_URL } from "../../lib/config"
import Icon from "../Icon/Icon"
import Popup from "../Popup/Popup"
import styles from "./DeckCard.module.css"

export default function DeckCard({ children, card, functionalMode, clickableMode, selected, ...props }){
    const cardStyle = {
        backgroundImage: `url(${BACKEND_URL}card/getImage/${card?.Oid})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...props.style,
    }

    return (
        <>
            <div 
                className={ `${styles.deckCard} 
                    ${functionalMode ? styles.deckCardFunctional : ""}
                    ${clickableMode ? styles.deckCardClickable : ""}
                    ${selected ? styles.deckCardSelected : ""}` }
                {...props} 
                style={ cardStyle }
            >
                { children }
                { functionalMode ? 
                    <div className={ `${styles.deckCardContent} glassmorphic` }>
                        <h3 className={ styles.deckCardContentTitle }>
                            <span className={ styles.deckCardContentTitleIndicator }><Icon iconName="checkbox-circle-fill" /></span>
                            { card.FirstName ?? "" } { card.LastName ?? "" }
                        </h3>
                    </div>
                : null }
            </div>
        </>
    )
}

export function DeckCardExample1({ ...props }){
    return (
        <>
            <DeckCard {...props}>
                <div className={ `${styles.deckCardExample} ${styles.deckCardExample1}` }>
                    <h3 className={ styles.deckCardExampleName }>Adam R. Smith</h3>
                    <p className={ styles.deckCardExampleEmail }>Data Engineer at Eapplico</p>
                    <p className={ styles.deckCardExampleEmail }>ar.smith@eapplico.com</p>
                </div>
            </DeckCard>
        </>
    )
}

export function DeckCardExample2({ ...props }){
    return (
        <>
            <DeckCard {...props}>
                <div className={ `${styles.deckCardExample} ${styles.deckCardExample2}` }>
                    <h3 className={ styles.deckCardExampleName }>Jeffrey M. Clinton</h3>
                    <p className={ styles.deckCardExampleEmail }>Founder & CEO at Supercold AI</p>
                    <p className={ styles.deckCardExampleEmail }>jeffclinton@supercold.ai</p>
                </div>
            </DeckCard>
        </>
    )
}

export function DeckCardEmpty({ ...props }){
    return (
        <DeckCard {...props}>
            <div className={ `${styles.deckCardExample} ${styles.deckCardExample2}` }>
                <h3 className={ styles.deckCardExampleName }>Empty Deck</h3>
                <p className={ styles.deckCardExampleEmail }>There is no card here.</p>
            </div>
        </DeckCard>
    )
}

export function NewDeckCard({ ...props }){
    return (
        <DeckCard {...props}>
            <div className={ `${styles.deckCardExample} ${styles.deckCardNew}` }>
                <h3 className={ styles.deckCardExampleName }>Create New Card</h3>
                <p className={ styles.deckCardExampleEmail }>Click here to create</p>
            </div>
        </DeckCard>
    )
}