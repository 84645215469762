import { useEffect, useState } from "react";
import { PendingConnectionList, ConnectionList } from "../../components/Connection/Connection";
import Construction from "../../components/Construction/Construction";
import { Content, Title } from "../../components/Content/Content";
import DeckCard from "../../components/Deck/DeckCard";
import { IconPlaceholder } from "../../components/Icon/Icon";
import MyDeck from "../../components/MyDeck/MyDeck";
import { CenterViewer } from "../../components/Viewer/Viewer";
import { useRemoveFooter, useTitle } from "../../lib/hooks";
import { fetchBackend } from "../../lib/util";

export default function Connections() {
    useTitle("Connections");
    useRemoveFooter();

    const [pendingConnections, setPendingConnections] = useState(null);
    const [userMap, setUserMap] = useState({});
    const [cardsMap, setCardsMap] = useState({});
    useEffect(() => {
        if(pendingConnections === null){
            loadPendingConnections();
        }
        if(allConnections === null){
            loadAllConnections();
        }
    })

    function loadPendingConnections(){
        fetchBackend("POST", "request/list", {})
        .then(data => data.json())
        .then(data => {
            if(data.error){
                console.error("Error fetching pending connections");
                return;
            }
            setPendingConnections(data.requests);
            updateUserMap(data.users);
            setCardsMap(data.cards);
        });
    }

    const [allConnections, setAllConnections] = useState(null);
    function loadAllConnections(){
        fetchBackend("POST", "connection/list", {})
        .then(data => data.json())
        .then(data => {
            if(data.error){
                console.error("Error fetching all connections");
                return;
            }
            setAllConnections(data.connections);
        });
    }

    function updateUserMap(newMap){
        setUserMap(currMap => {
            return {
                ...currMap,
                ...newMap
            }
        })
    }

    return (
        <>
            <CenterViewer>
                {/* <Construction />
                <hr/> */}
                <Title style={{ marginBottom: 0  }}>Pending Connections</Title>
                <Content>The following people added your card to their decks. You can add them to your deck, too.</Content>
                { pendingConnections?.length === 0 ?
                    <IconPlaceholder iconName="stack-line">
                        No pending connections so far
                    </IconPlaceholder> 
                : 
                    <PendingConnectionList
                        reloadConnections={ loadPendingConnections }
                        usersMap={ userMap }
                        cardsMap={ cardsMap }
                        connections={ pendingConnections }
                    />
                 }
                <hr/>
                <Title>All Connections</Title>
                <ConnectionList
                    connections={ allConnections }
                />
            </CenterViewer>
        </>
    );
}