import { Title } from "../../components/Content/Content";
import DeckCard from "../../components/Deck/DeckCard";
import MyDeck from "../../components/MyDeck/MyDeck";
import { CenterViewer } from "../../components/Viewer/Viewer";
import { useRemoveFooter, useTitle } from "../../lib/hooks";

export default function MyDeckPage() {
    useTitle("MyDeck");
    useRemoveFooter();

    return (
        <>
            <CenterViewer style={{ maxWidth: "100vw", width: "100vw", margin: "50px 0" }}>
                <MyDeck />
            </CenterViewer>
        </>
    );
}