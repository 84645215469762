import { useEffect } from "react";

export function useTitle(title){
    useEffect(() => {
        document.title = `${title} | Eapplico`;
    }, [])
}

export function useRemoveFooter(){
    useEffect(() => {
        document.querySelector("footer").style.display = "none";
        return () => {
            document.querySelector("footer").style.display = "block";
        }
    }, [])
}