import styles from "./ShowcaseScreens.module.css";

export default function ShowcaseScreens({ image, ...props }){
    return (
        <div className={ styles.showcaseScreenBase }>
            <div className={ styles.leftScreenOuter }>
                <div className={ styles.leftScreen } style={{ backgroundImage: `url(${image})` }}>

                </div>
            </div>
            {/* <div className={ styles.rightScreen }>

            </div> */}
        </div>
    )
}