import AnimateOnScroll from "../Animations/AppearOnScroll";
import styles from "./SharePhone.module.css";

export default function SharePhone(){
    return (
        <div className={ styles.root }>
            <AnimateOnScroll
                originalStyle={{ transform: "translateX(200px)", transition: "1s" }}
                activatedStyle={{ transform: "translateX(-30px)" }}
            >
            <div className={ styles.phone }>
                <div className={ styles.phoneScreen }>
                    <div className={ styles.phoneScreenOverlay }>

                        <AnimateOnScroll
                            originalStyle={{ transform: "translateX(0)", transition: "1s" }}
                            activatedStyle={{ transform: "translateX(200px) translateY(30px)" }}
                        >
                            <div className={ `glassmorphic ${styles.phoneScreenContent}` }>
                                <AnimateOnScroll
                                    originalStyle={{ transform: "translateX(0)", transition: "1s" }}
                                    activatedStyle={{ transform: "translateX(200px) translateY(30px)" }}
                                >
                                    <div className={ `glassmorphic ${styles.phoneScreenContentTop}` }>

                                    </div>
                                    <div className={ `glassmorphic ${styles.phoneScreenContentBottom}`}>

                                    </div>
                                </AnimateOnScroll>
                            </div>
                        </AnimateOnScroll>
                    </div>
                </div>
            </div>
            </AnimateOnScroll>
        </div>
    )
}