import { Link } from "react-router-dom";
import { CenterViewer } from "../Viewer/Viewer";
import styles from "./Footer.module.css";
import landingStyles from "../../pages/Landing/Landing.module.css";
import Logo from "../Logo/Logo";

export default function Footer(){
    return (
        <footer className={ styles.footer }>
            <div className={ landingStyles.landingRadiation } style={{ width: "30vw", height: "30vw", position: "relative", top: 30, left: `calc(100vw - 30px)`, marginBottom: "-30vw" }}>
                <div className={ landingStyles.landingRadiationOverlay }
                    style={{ background: `radial-gradient(circle at center center, #ffffff11 0%, #ffffff 70%)` }}
                ></div>
            </div>
            <CenterViewer style={{ maxWidth: 1400, paddingTop: 0 }}>
                <div className={ styles.footerCenter }>
                    <div style={{ paddingRight: 0, hyphens: "auto" }}>
                        <Logo style={{ width: 170, margin: 0, padding: "10px 0" }} />
                        Building the future of professional network management and business card exchange.
                        <br/><br/>
                    </div>
                    <div></div>
                    <FooterColumn title="Platform" links={[
                        { to: "/mydeck", text: "MyDeck" },
                        { to: "/scan", text: "Scan Card" },
                        { to: "/notifications", text: "Notifications" },
                        { to: "/account", text: "My Profile" },
                        { to: "/account/login", text: "Log In" },
                    ]} />
                    <FooterColumn title="Company" links={[
                        { to: "/landing", text: "Overview" },
                        { to: "/company", text: "About Us" },
                        { to: "/investors", text: "Investors" },
                        { to: "/joinus", text: "Join Us" },
                    ]} />
                    <FooterColumn title="Other Links" links={[
                        { to: "/terms", text: "Terms of Service" },
                        { to: "/privacy", text: "Privacy Policies" },
                        { to: "/", text: "Download on iOS" },
                    ]} />
                </div>
            </CenterViewer>
            <br/><br/>
            <div className={ styles.footerBottom }>
                <div className={ styles.footerBottomLeft }>
                    &copy;2024 Eapplico
                </div>
                <div className={ styles.footerBottomRight }>
                    service@eapplico.com
                </div>
            </div>
        </footer>
    )
}

export function FooterColumn({ links, title, ...props }){
    return (
        <div className={ styles.footerColumn } {...props}>
            <h3 className={ styles.footerColumnTitle }>{ title }</h3>
            <ul style={{ paddingLeft: 5 }}>
                { links.map((link, i) => (
                    <li key={ i } className={ styles.footerColumnLink }>
                        <Link to={ link.to }>{ link.text }</Link>
                    </li>
                )) }
            </ul>
        </div>
    )
}