import { useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";


export default function LoginOnly({ children, componentMode, redirect, fallback }){
    const appStates = useContext(AppContext);
    if(appStates.user === false){
        if(componentMode) return <>{ fallback }</>;
        const hasLoggedIn = localStorage.getItem("hasLoggedIn");
        if(!hasLoggedIn){
            if(["/", "/landing", "/home"].includes(window.location.pathname))
                window.location.href = "/landing";
            else
                window.location.href = "/account/login";
        }
        else window.location.href = "/account/login";
        // navigate(redirect || "/account/login");
        return <></>;
    }
    if(appStates.user === null){
        // return <Loading style={{ margin: "200px auto" }} />;
        return <></>;
    }
    return children;
}