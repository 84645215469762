import styles from "./SideNotification.module.css"


export default function SideNotification({ notifications }){

    return (
        <div className={ styles.notificationRoot }>
            { (notifications || []).map((notification, index) => {
                return <div className={ styles.notificationBlock } 
                    key={ `notification-${notification.serialId}` }>
                    { notification.content }
                </div>
            }) }
        </div>
    )
}