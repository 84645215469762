import styles from "./Content.module.css"

export function Title({ children, ...props }) {
    return <h1 className={ styles.title } {...props}>
        { children }
    </h1>
}

export function Subtitle({ children, ...props }) {
    return <h2 className={ styles.subtitle } {...props}>
        { children }
    </h2>
}

export function Content({ children, ...props }) {
    return <p className={ styles.content } {...props}>
        { children }
    </p>
}