import styles from "./Quotation.module.css";

export default function Quotation({ children, source, ...props }){
    return (
        <>
            <div className={ styles.quotation } {...props}>
                { children }
                <div className={ styles.quotationSource }>
                    { source }
                </div>
            </div>
        </>
    )
}