import { Title } from "../../components/Content/Content";
import NewCard from "../../components/NewCard/NewCard";
import { CenterViewer } from "../../components/Viewer/Viewer";
import { useTitle } from "../../lib/hooks";

export default function Scan(){
    useTitle("Scan New Card");

    return (
        <>
            <CenterViewer>
                <Title>Scan New Card</Title>
                <NewCard />
            </CenterViewer>
        </>
    )
}