import React, { createContext, useContext} from "react";
import SignupCss from "./Signup.module.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { fetchBackend } from "../../lib/util";
import { useTitle } from "../../lib/hooks";
import { Input } from "../../components/Form/Form";
import { Button } from "../../components/Button/Button";
import Radiation from "../../components/Artistic/Radiation";
import Card from "../../components/Card/Card";
import { AppContext } from "../../components/AppContext";
// import {context} from "../../App.js"

function Signup ({setSelected, setlogText}) {
  const appStates = useContext(AppContext);
  let navigate = useNavigate();
  useTitle("Signup");

  function submitData (signupData) {
    fetchBackend("POST", `user/create/${signupData.get("username")}`, {
      "Email": signupData.get("email"), 
      "Password": signupData.get("password")
    })
    .then(response => response.json())
    .then(data => {
        console.log('Data from backend', data, data.error);
        if(data.error){
          appStates.addSideNotification(`Signup error: ${data.error}`);
        }
        else{
            console.log("success");
            localStorage.setItem('userToken', data["token"]);
            // Force reload of the entire app
            window.location.href = "/";
        }
    });
  };

  const catchSubmit = (event) => {
    event.preventDefault();
    const loginData = new FormData(event.currentTarget);
    console.log({
      username: loginData.get("usernameEmail"),
      password: loginData.get("password"),
    });
    submitData(loginData);
  };
  return (
    <>
      <Radiation size={ 2000 } />
      <div className={SignupCss.wholepage}>
        <div className={`${SignupCss.loginBox} glassmorphic`}>
          <div className={SignupCss.loginTitle}>Eapplico Signup</div>
          <form className={SignupCss.loginForm} onSubmit={catchSubmit}>
            <Input
              label="Username"
              type="text"
              id="username"
              name="username"
              placeholder="some.name"
              style={{ width: "100%", marginBottom: 25 }}
            />
            <Input
              label="User Email"
              type="text"
              id="email"
              name="email"
              placeholder="name@example.com"
              style={{ width: "100%", marginBottom: 25 }}
            />
            <Input
              label="User Password"
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              style={{ width: "100%", marginBottom: 25 }}
            />
            <div className={SignupCss.loginButtonContainer}>
              <Button style={{ width: "100%", marginTop: 20 }}>
                Signup
              </Button>
            </div>

            <div className={ SignupCss.loginHintText }>
              Already have an account? <a href="/account/login">Login</a>
            </div>

          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
