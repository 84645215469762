import Icon from "../Icon/Icon";
import styles from "./Popup.module.css";

export default function Popup({ children, showing, size, toggleFunction, title, contentStyle, baseProps, ...props }){

    function baseClicked(e){
        if (e.target.classList.contains(styles.popupBaseLayer)){
            e.preventDefault();
            toggleFunction && toggleFunction(false);
        }
    }

    if (!showing) return <></>;
    return (
        <div className={ styles.popupBaseLayer } onClick={ baseClicked } {...baseProps}>
            <div className={ `${styles.popupContainer} ${styles[`popupContainer-${size}`]}` } {...props}>
                <div className={ styles.popupContainerTop }>
                    <h3 className={ styles.popupContainerTitle }>{ title }</h3>
                    <button className={ styles.popupContainerClose } onClick={ () => toggleFunction && toggleFunction(false) }>
                        <Icon style={{ margin: 12, display: "block" }} iconName={ "close-line" } />
                    </button> 
                </div>
                <div className={ styles.popupContainerContent } style={ contentStyle }>
                    { children }
                </div>
            </div>
        </div>
    )
}