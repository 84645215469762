import styles from "./Form.module.css"
import myDeckStyles from "../../components/MyDeck/MyDeck.module.css";
import DeckCard from "../Deck/DeckCard";
import { forwardRef } from "react";

export function Input({ label, ...props }){
    return <>
        {  label && <FormLabel>{label}</FormLabel> }
        <input className={ styles.input } {...props} />
    </>
}

export const TextArea =  forwardRef(({ label, ...props }, ref) => {
    return <>
        {  label && <FormLabel>{label}</FormLabel> }
        <textarea className={ styles.input } ref={ref} {...props} />
    </>
})

export function FormLabel({ ...props }){
    return <>
        <div className={ styles.formLabel } {...props} />
    </>
}

export function SelectCard({ cards, onChange, selected, ...props }){

    return (
        <>
            <div className={ myDeckStyles.myDeckContent }
                style={{ width: "100%", gap: 45, paddingBottom: 50 }}
            >
                { (cards ?? []).map((card, index) => {
                    return <DeckCard
                        selected={ card?.Oid === selected }
                        clickableMode
                        functionalMode
                        key={ index }
                        card={ card }
                        onClick={ () => { onChange && onChange(card?.Oid === selected ? null : card) } }
                    />
                }) }
            </div>
        </>
    )
}