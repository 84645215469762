import { useContext, useEffect, useState } from "react";
import { Button, IconButton, IconTextButton, IconTextButtonStack } from "../Button/Button";
import Deck from "../Deck/Deck";
import DeckCard from "../Deck/DeckCard";
import styles from "./MyDeck.module.css";
import { fetchBackend } from "../../lib/util";
import Popup from "../Popup/Popup";
import { Input } from "../Form/Form";
import { AppContext } from "../AppContext";
import { NewCardPopup } from "../NewCard/NewCard";
import { Content, Title } from "../Content/Content";
import Icon from "../Icon/Icon";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../Card/Card";
import DeckScheme from "./DeckScheme";
import DeckCardDetailsPopup from "../Deck/DeckCardDetails";
import { QRAndLinkPopup } from "../NewCard/QRAndLink";

export function MyDeckPathButton({ folderOid, setCurrentFolderId, children, ...props}){
    return (
    <span 
            className={ styles.myDeckPathButton }
            onClick={ () => setCurrentFolderId && setCurrentFolderId(folderOid) }
        >
            { children }
        </span>
    )

}

export default function MyDeck({ ...props }){
    const appStates = useContext(AppContext);
    const navigate = useNavigate();

    const { urlFolderId } = useParams();
    const [currentFolderId, setCurrentFolderId] = useState(urlFolderId ?? "ROOT");
    const [folderData, setFolderData] = useState({});
    useEffect(() => {
        if(urlFolderId && urlFolderId !== currentFolderId){
            setCurrentFolderId(urlFolderId);
        }
    }, [urlFolderId])
    function setNewFolderData(folderId, data){
        setFolderData(curr => {
            return {
                ...curr,
                [folderId]: data
            }
        } )
    }
    function fetchFolderData(){
        fetchBackend("POST", "folder/get", {
            Oid: currentFolderId,
        })
        .then(data => data.json())
        .then(data => {
            setNewFolderData(currentFolderId, data);
            for(let folder of data.subfolders){
                setNewFolderData(folder.Oid, {folder: folder, subfolders: [], cards: [], subFolderContents: {}});
            }
        })
    }

    useEffect(() => {
        fetchFolderData();
        window.history.pushState({}, "", `/mydeck/${currentFolderId}`);
    }, [currentFolderId])

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectionType, setSelectionType] = useState(null);
    function selectedFolderClicked(folderId, e){
        if(e.detail === 2 && folderId === false){
            navigate("/myowncards");
        }
        if(e.detail === 2 && folderId !== false){
            setCurrentFolderId(folderId);
            setSelectedItemId(null);
        }else if(folderId !== false){
            setNewName(null);
            if(selectedItemId === folderId || folderId === null){
                setSelectedItemId(null);
            }else{
                setSelectionType("folder");
                setSelectedItemId(folderId);
            }
        }
    }
    const [selectedCard, setSelectedCard] = useState(null);
    const [showingCardDetails, setShowingCardDetails] = useState(false);
    function selectedCardClicked(cardId, e){
        setNewName(null);
        const card = folderData[currentFolderId]?.cards.find(
            card => card.Oid === cardId);
        setSelectedCard(card);
        if(e.detail === 2 && selectionType === "card"){
            setShowingCardDetails(true);
        }
        if(selectedItemId === cardId || cardId === null){
            setSelectedItemId(null);
        }else{
            setSelectionType("card");
            setSelectedItemId(cardId);
        }
    }

    const [showingRenamePopup, setShowingRenamePopup] = useState(false);
    const [showingAddDeckPopup, setShowingAddDeckPopup] = useState(false);
    const [showingDeleteDeckPopup, setShowingDeleteDeckPopup] = useState(false);
    const [showingDeleteCardPopup, setShowingDeleteCardPopup] = useState(false);
    const [showingAddCardPopup, setShowingAddCardPopup] = useState(false);
    const [showingMoveCardPopup, setShowingMoveCardPopup] = useState(false);
    const [showingQrAndLinkPopup, setShowingQrAndLinkPopup] = useState(false);
    function toolbarTrigger(event){
        if(!["add-deck", "add-card", "search-user", "search", "qr-and-link"].includes(event) && !selectedItemId) return;
        if (event === "add-deck") setShowingAddDeckPopup(true);
        if (event === "add-card") setShowingAddCardPopup(true);
        if (event === "qr-and-link") setShowingQrAndLinkPopup(true);
        if (event === "search") appStates.showAISearch();
        if(selectionType === "folder"){
            if (event === "rename") setShowingRenamePopup(true);
            if (event === "delete") setShowingDeleteDeckPopup(true);
        }else{
            if (event === "delete") setShowingDeleteCardPopup(true);
            if (event === "move") setShowingMoveCardPopup(true);
        }
    }

    const [newName, setNewName] = useState(null);
    function saveNewName(){
        if(!newName) return;
        appStates.addSideNotification("Renaming...")
        fetchBackend("PUT", "folder/update", {
            Oid: selectedItemId,
            Name: newName,
        })
        .then(data => data.json())
        .then(data => {
            if(!data.error){
                fetchFolderData();
                setShowingRenamePopup(false);
            }else{
                appStates.addSideNotification("An error occurred while renaming the folder. Please try again.")
            }
        })
    }

    const [newDeckName, setNewDeckName] = useState(null);
    function saveNewDeck(){
        if(!newDeckName) return;
        appStates.addSideNotification("Creating new deck...");
        fetchBackend("POST", "folder/create", {
            Name: newDeckName,
            ParentOid: currentFolderId,
        })
        .then(data => data.json())
        .then(data => {
            if(!data.error){
                fetchFolderData();
                setShowingAddDeckPopup(false);
            }else{
                appStates.addSideNotification("An error occurred while creating the deck. Please try again.")
            }
        })
    }

    function deleteDeck(){
        appStates.addSideNotification("Deleting deck...");
        fetchBackend("DELETE", "folder/delete", {
            Name: newDeckName,
            Oid: selectedItemId
        })
        .then(data => data.json())
        .then(data => {
            if(!data.error){
                fetchFolderData();
                setShowingDeleteDeckPopup(false);
                setSelectedItemId(null);
            }else{
                appStates.addSideNotification("An error occurred while deleting the deck. Please try again.")
            }
        })
    }

    function deleteCard(){
        appStates.addSideNotification("Deleting card...");
        fetchBackend("DELETE", "card/delete", {
            CardOid: selectedItemId
        })
        .then(data => data.json())
        .then(data => {
            if(!data.error){
                fetchFolderData();
                setShowingDeleteCardPopup(false);
                setSelectedItemId(null);
            }else{
                appStates.addSideNotification("An error occurred while deleting the card. Please try again.")
            }
        })
    }

    function moveCard(folderId){
        appStates.addSideNotification("Moving card...");
        fetchBackend("PUT", "card/move", {
            CardOid: selectedItemId,
            ParentOid: folderId
        })
        .then(data => data.json())
        .then(data => {
            if(!data.error){
                fetchFolderData();
                setShowingMoveCardPopup(false);
                setSelectedItemId(null);
            }else{
                appStates.addSideNotification("An error occurred while moving the card. Please try again.")
            }
        })
    }

    function blankClicked(e){
        if(e.target === e.currentTarget){
            setSelectedItemId(null);
        }
    }

    return (
        <>
            <div className={ styles.myDeck } {...props}>
                <MyDeckToolbar 
                    trigger={ toolbarTrigger } 
                    selectionType={ selectionType }
                    hasSelected={ selectedItemId !== null }
                />
                <Title style={{ marginTop: 40, paddingLeft: 30, color: "#444" }}>
                    <MyDeckPathButton 
                        folderOid="ROOT" 
                        setCurrentFolderId={ setCurrentFolderId }
                    >
                        All Decks
                    </MyDeckPathButton>
                    { currentFolderId !== "ROOT" ? 
                        <>
                            <Icon iconName={ "arrow-drop-right-line" } />
                            <MyDeckPathButton
                                folderOid={ folderData[currentFolderId]?.folder?.Oid }
                                setCurrentFolderId={ setCurrentFolderId }
                            >
                                { folderData[currentFolderId]?.folder?.Name }
                            </MyDeckPathButton>
                        </>
                    : null } 
                </Title>
                <div className={ styles.myDeckContent } onClick={ (e) => blankClicked(e) }>

                    { currentFolderId === "ROOT" ?
                        <Deck 
                            onClick={ (e) => selectedFolderClicked(false, e) }
                            folder={{ Name: "My Own Cards" }}
                            myOwnCards
                        />
                    : null }

                    { folderData[currentFolderId]?.subfolders?.map(folder => {
                        return (
                            <Deck 
                                cardOids={ folderData[currentFolderId]?.subFolderContents[folder.Oid] ?? [] }
                                selected={ selectedItemId === folder.Oid}
                                onClick={ (e) => selectedFolderClicked(folder.Oid, e) }
                                key={ folder.Oid }
                                folder={ folder }
                            />
                        )
                    }) }
                    { folderData[currentFolderId]?.cards?.map(card => { 
                        return (
                            <DeckCard functionalMode
                                style={{ transform: "translateY(60px)" }}
                                onClick={ (e) => selectedCardClicked(card.Oid, e) }
                                selected={ selectedItemId === card.Oid}
                                key={ card.Oid }
                                card={ card }
                            />
                        )
                    }) }
                </div>

            </div>
            
            {/* Popup for Renaming a Deck */}
            <Popup
                title="Rename Deck"
                showing={ showingRenamePopup }
                toggleFunction={ setShowingRenamePopup }
                size="mini"
            >
                <Input
                    onChange={ (e) => setNewName(e.target.value) }
                    label="New Name"
                    defaultValue={ folderData[selectedItemId]?.Name }
                    style={{ marginBottom: 20 }}
                />
                <Button onClick={ saveNewName }>
                    Rename
                </Button>
            </Popup>

            {/* Popup for QR and Link */}
            <QRAndLinkPopup
                onComplete={ fetchFolderData }
                showing={ showingQrAndLinkPopup }
                toggleFunction={ setShowingQrAndLinkPopup }
            />
                
            {/* Popup for Creating a New Deck */}
            <Popup
                title="Add New Deck"
                showing={ showingAddDeckPopup }
                toggleFunction={ setShowingAddDeckPopup }
                size="mini"
            >
                { currentFolderId !== "ROOT" ?
                    <Card>
                        <Content>Creating a deck within another deck is currently not supported.</Content>
                    </Card>
                : 
                    <>
                        <Input
                            onChange={ (e) => setNewDeckName(e.target.value) }
                            label="Deck Name"
                            style={{ marginBottom: 20 }}
                        />
                        <Button onClick={ saveNewDeck }>
                            Create
                        </Button>    
                    </>
                }
            </Popup>
                
            {/* Popup for Deleting a Deck */}
            <Popup
                title="Delete Deck"
                showing={ showingDeleteDeckPopup }
                toggleFunction={ setShowingDeleteDeckPopup }
                size="mini"
            >
                <Button 
                    onClick={ deleteDeck } 
                    style={{ backgroundColor: "red" }}
                >
                    Confirm Deletion
                </Button>
            </Popup>
                
            {/* Popup for Deleting a Card */}
            <Popup
                title="Delete Card"
                showing={ showingDeleteCardPopup }
                toggleFunction={ setShowingDeleteCardPopup }
                size="mini"
            >
                <Button 
                    onClick={ deleteCard } 
                    style={{ backgroundColor: "red" }}
                >
                    Confirm Deletion
                </Button>
            </Popup>

            {/* Popup for moving a Card */}
            <Popup
                title="Move Card"
                showing={ showingMoveCardPopup }
                toggleFunction={ setShowingMoveCardPopup }
                size="medium"
                contentStyle={{ padding: 0 }}
            >
                <DeckScheme 
                    onSelect={ moveCard }
                    folderDataProps={ folderData }
                />
            </Popup>

            {/* Popup for Scanning a Card */}
            <NewCardPopup
                currentFolderId={ currentFolderId }
                showing={ showingAddCardPopup }
                toggleFunction={ setShowingAddCardPopup }
                onComplete={ fetchFolderData }
            />

            {/* Popup for Displaying Card Details */}
            <DeckCardDetailsPopup 
                noShare 
                showing={ showingCardDetails }
                toggleFunction={ setShowingCardDetails }
                card={ selectedCard }
            />
        </>
    )
}

export function MyDeckToolbar({ trigger, hasSelected, selectionType, ...props }){

    const [showingAddStack, setShowingAddStack] = useState(false);
    function buttonClicked(event){
        setShowingAddStack(false);
        trigger && trigger(event);
    }

    return (
        <div className={ styles.myDeckToolbar } {...props}>
            <IconTextButton primary 
                iconName="add-line"
                onClick={ () => setShowingAddStack(!showingAddStack) }
                style={{ borderRadius: 0, marginRight: 10 }}
            >
                Add
            </IconTextButton>
            <IconTextButtonStack 
                style={{ position: "absolute", top: 50, left: 10, 
                    display: showingAddStack ? "block" : "none" }}
            >
                <IconTextButton  
                    iconName="folder-add-line"
                    onClick={ () => buttonClicked("add-deck") }
                >
                    &nbsp;Add New Deck
                </IconTextButton>
                <IconTextButton  
                    iconName="add-box-line"
                    onClick={ () => buttonClicked("add-card") }
                >
                    &nbsp;Add New Card
                </IconTextButton>
                <IconTextButton  
                    iconName="import-line"
                    onClick={ () => buttonClicked("qr-and-link") }
                >
                    &nbsp;From QR Code or Link
                </IconTextButton>
                <IconTextButton  
                    iconName="search-2-line"
                    onClick={ () => buttonClicked("search-user") }
                >
                    &nbsp;Search User
                </IconTextButton>
            </IconTextButtonStack>
            <IconTextButton 
                onClick={ () => buttonClicked("search") }
                iconName="search-eye-line"
            >
                AI-Search
            </IconTextButton>
            <IconTextButton 
                disabled={ !hasSelected || selectionType === "folder" }
                onClick={ () => buttonClicked("move") }
                iconName="folder-shared-line"
            >
                Move
            </IconTextButton>
            <IconTextButton 
                disabled={ !hasSelected || selectionType === "card" }
                onClick={ () => buttonClicked("rename") }
                iconName="input-field"
            >
                Rename
            </IconTextButton>
            <IconTextButton 
                disabled={ !hasSelected }
                onClick={ () => buttonClicked("delete") }
                iconName="delete-bin-line"
            >
                Delete
            </IconTextButton>
        </div>
    )
}