import { useContext } from "react";
import { fetchBackend } from "../../lib/util";
import Avatar from "../Avatar/Avatar";
import { Button } from "../Button/Button";
import DeckCard from "../Deck/DeckCard";
import { IconTextPair } from "../Icon/Icon";
import Popup from "../Popup/Popup";
import { TabsViewer } from "../Viewer/Viewer";
import styles from "./ConnectionPopup.module.css";
import { AppContext } from "../AppContext";

export default function ConnectionPopup({ connection, request, user, card, reloadConnections, displayCard, tab, ...props }){
    const appStates = useContext(AppContext);
    
    function acceptRequest(){
        updateRequest("accepted");
    }
    function declineRequest(){
        updateRequest("declined");
    }
    function updateRequest(status){
        fetchBackend("POST", "request/respond", {
            Oid: request.Oid,
            Status: status
        })
        .then(data => data.json())
        .then(data => {
            if(data.error){
                appStates.addSideNotification("Request accepted!");
                return;
            }
            if(status === "accepted"){
                appStates.addSideNotification("Request accepted! The card is now in your MyDeck.");
            } else {
                appStates.addSideNotification("Request declined.");
            }
            reloadConnections && reloadConnections();
            props.toggleFunction && props.toggleFunction(false);
        });
    }

    return (
        <Popup
            title="View Connection"
            {...props}
        >
            { request ? 
                <div className={ styles.connectionPopupButtons }>
                    <Button style={{ borderRadius: 0, borderBottomLeftRadius: `var(--border-radius)`, width: "50%", background: "red", padding: 0 }}
                        onClick={ declineRequest }
                    >
                        Decline
                    </Button>
                    <Button style={{ borderRadius: 0, borderBottomRightRadius: `var(--border-radius)`, width: "50%", padding: 0 }}
                        onClick={ acceptRequest }
                    >
                        Add to MyDeck
                    </Button>
                </div>
            : null }
            <div className={ styles.connectionPopupTop } >
                <div className={ styles.connectionPopupOverlay }>
                    <DeckCard
                        card={ card }
                    />
                </div>      
                <div className={ styles.connectionPopupTopContent }>
                    <Avatar size={ 150 } username={ user?.Username } style={{ }} />
                    <div className={ styles.connectionPopupTopContentName }>                    
                        { `${user?.FirstName ?? ""} ${user?.LastName ?? ""}` }
                    </div>
                    { user?.Position ? 
                        <div className={ styles.connectionPopupTopContentDescription }>
                            { user.Position }
                        </div>
                    : null }    
                    { user?.Headline ? 
                        <div className={ styles.connectionPopupTopContentDescription }>
                            { user.Company }
                        </div>
                    : null } 
                </div>
            </div>
            <TabsViewer tab={ tab ?? 0 } tabs={[
                displayCard !== false ? { name: "Card Details", content: <div style={{ fontSize: 18, lineHeight: 2 }}>
                    <IconTextPair 
                        iconName="user-3-line"
                        style={{ fontSize: 20 }} 
                    >
                        { card?.FirstName } { card?.MiddleName } { card?.LastName }
                    </IconTextPair>
                    { card?.Position ? 
                        <IconTextPair 
                            iconName="passport-line"
                            style={{ fontSize: 20 }} 
                        >
                            { card?.Position }
                        </IconTextPair>
                    : null }
                    { card?.Headline ? 
                        <IconTextPair 
                            iconName="open-arm-line"
                            style={{ fontSize: 20 }} 
                        >
                            { card?.Headline }
                        </IconTextPair>
                    : null }
                    { card?.PublicEmail ? 
                        <IconTextPair 
                            iconName="mail-line"
                            style={{ fontSize: 20 }} 
                        >
                            { card?.PublicEmail }
                        </IconTextPair>
                    : null }
                    { card?.Phone ? 
                        <IconTextPair 
                            iconName="phone-line"
                            style={{ fontSize: 20 }} 
                        >
                            { card?.Phone }
                        </IconTextPair>
                    : null }
                    { card?.Address ? 
                        <IconTextPair 
                            iconName="map-pin-line"
                            style={{ fontSize: 20 }} 
                        >
                            { card?.Address }
                        </IconTextPair>
                    : null }
                    <div style={{ width: "100%", height: 70 }}></div>
                </div> } : false,
                { name: "Card Owner Details", content: <div style={{ fontSize: 18, lineHeight: 2 }}>
                    <IconTextPair 
                        iconName="user-3-line"
                        style={{ fontSize: 20 }} 
                    >
                        { user?.FirstName } { user?.MiddleName } { user?.LastName }
                    </IconTextPair>
                    { user?.Position ? 
                        <IconTextPair 
                            iconName="passport-line"
                            style={{ fontSize: 20 }} 
                        >
                            { user?.Position }
                        </IconTextPair>
                    : null }
                    { user?.Headline ? 
                        <IconTextPair 
                            iconName="open-arm-line"
                            style={{ fontSize: 20 }} 
                        >
                            { user?.Headline }
                        </IconTextPair>
                    : null }
                    { user?.PublicEmail ? 
                        <IconTextPair 
                            iconName="mail-line"
                            style={{ fontSize: 20 }} 
                        >
                            { user?.PublicEmail }
                        </IconTextPair>
                    : null }
                    { user?.Phone ? 
                        <IconTextPair 
                            iconName="phone-line"
                            style={{ fontSize: 20 }} 
                        >
                            { user?.Phone }
                        </IconTextPair>
                    : null }
                    { user?.Address ? 
                        <IconTextPair 
                            iconName="map-pin-line"
                            style={{ fontSize: 20 }} 
                        >
                            { user?.Address }
                        </IconTextPair>
                    : null }
                    <div style={{ width: "100%", height: 70 }}></div>
                </div> }
            ]} />
        </Popup>
    )
}