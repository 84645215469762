import { BACKEND_URL } from "./config";

export function fetchBackend(method, path, data){
    if(path.startsWith("/") && path.length > 1) path = path.substring(1);
    const url  = BACKEND_URL + path;
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: localStorage.getItem("userToken"),
        ...data
      }),
    }
    return fetch(url, requestOptions);
}

export function parseMarkdown(MarkdownIt, content){
  const parser = MarkdownIt({
      breaks: true,
      typographer: true,
  })
  return parser.render(content);
}

export async function getGitHubSource(username, repository, file, branch, then=null){
  const url = `https://raw.githubusercontent.com/${username}/${repository}/${branch}/${file}`;
  const requestOptions = {
    method: 'GET'
  };
  
  const response = await fetch(url, requestOptions);
  const data = await response.text();
  then && then(data);
  return data;
}