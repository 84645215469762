import styles from "./Card.module.css"


export default function Card({ children, noPadding, glassmorphic, ...props }) {

    const className = `
        ${styles.card}
        ${noPadding ? styles.noPadding : ""}
        ${glassmorphic ? "glassmorphic" : ""}
        ${glassmorphic ? styles.glassmorphicCard: ""}
    `;

    return <div className={ className } {...props}>
        { children } 
    </div>
}

export function GlassmorphicCard({ children, ...props }){
    return (
        <Card glassmorphic {...props}>
            { children }
        </Card>
    )
}