import './App.css';
import TopBar from "./components/TopBar/TopBar.jsx";
import Login from "./pages/Login/Login.jsx";
import Signup from "./pages/Signup/Signup.jsx";
import Home from "./pages/Home/Home.jsx"
import React, { useState, useContext, createContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import { fetchBackend } from './lib/util.js';
import { AppContext } from './components/AppContext.jsx';
import Account from './pages/Account/Account.jsx';
import Connections from './pages/Connections/Connections.jsx';
import SideNotification from './components/Notification/SideNotification.jsx';
import { NOTIFICATION_TIME } from './lib/config.js';
import Notifications from './pages/Notifications/Notifications.jsx';
import Landing from './pages/Landing/Landing.jsx';
import About from './pages/About/About.jsx';
import Footer from './components/Footer/Footer.jsx';
import Markdown from './pages/Markdown/Markdown.jsx';
import Popup from './components/Popup/Popup.jsx';
import PageChangeTrigger from './components/PageChangeTrigger/PageChangeTrigger.jsx';
import MyDeckPage from './pages/MyDeck/MyDeck.jsx';
import Scan from './pages/Scan/Scan.jsx';
import MyOwnCards from './pages/MyOwnCards/MyOwnCards.jsx';
import NotFound from './pages/404/404.jsx';
import ShareCard from './pages/ShareCard/ShareCard.jsx';
import LoginOnly from './components/LoginOnly/LoginOnly.jsx';
import Investors from './pages/Investors/Investors.jsx';
import JoinUs from './pages/JoinUs/JoinUs.jsx';
import AISearch from './components/AISearch/AISearch.jsx';

function App() {
  const [token, setToken] = useState("");
  const [logText, setlogText] = useState(() => {
    if (localStorage.getItem("userToken") === null) {
      return "Login";
    }

    return "Logout";
  });

  const [renderSerial, setRenderSerial] = useState(0);

  const [showingAISearch, setShowingAISearch] = useState(false);

  const [appStates, setAppStates] = useState({
    user: null,
    initUser,
    addSideNotification,
    sideNotification: [],
    showAISearch: () => setShowingAISearch(true),
    closeAISearch: () => setShowingAISearch(false),
    triggerRerender: () => setRenderSerial(curr => curr + 1)
  });
  function updateAppStates(updatedObj) {
    setAppStates((curr) => {
      return {
        ...curr,
        ...updatedObj
      }
    })
  }

  useEffect(() => {
    if(appStates.user === null) initUser();
  }, []);

  function initUser(){
    fetchBackend("POST", "user/init", {})
    .then(res => res.json())
    .then(data => {
      if(!data.error) {
        updateAppStates({
          user: data.user
        })
      }else{
        updateAppStates({
          user: false
        })
      }
    })
  }

  function addSideNotification(content) {
    const serialId = Math.floor(Math.random() * 10000000);
    appStates.sideNotification.push({ content, serialId });
    appStates.triggerRerender();

    setTimeout(() => {
      const toRemove = appStates.sideNotification.findIndex(notificationObject => {
          return notificationObject.serialId === serialId;
      });
      appStates.sideNotification.splice(toRemove, 1);
      appStates.triggerRerender();
    }, NOTIFICATION_TIME);
  }

  function pageChangeTrigger(){
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }

  return (
    <div className="App">
      <AppContext.Provider value={appStates}>
        <SideNotification 
          notifications={ appStates.sideNotification } 
        />
        { showingAISearch ? 
          <AISearch
            onToggleFunction={ () => setShowingAISearch(false) }
          />
        : null }
        <BrowserRouter>
            <header id="header" className="header">
              <div id="scroll-anchor"></div>
              <TopBar logText={logText} setlogText={setlogText}/>
            </header>
            <main style={{ overflowY: "visible" }}>
              <PageChangeTrigger trigger={ pageChangeTrigger } />
              <Routes>
                { /** Public display pages */ }
                <Route path="/landing" element={<Landing />} /> 
                <Route path="/company" element={ <About /> } />
                <Route path="/account/login" element={<Login setlogText={setlogText}/>} />
                <Route path="/account/signup" element={<Signup setlogText={setlogText}/>} />
                <Route path="/share-card/:CardOid" element={<ShareCard />} />
                <Route path="/investors" element={<Investors />} />
                <Route path="/joinus" element={<JoinUs />} />

                { /** Public Markdown pages */ }
                <Route path="/terms" element={<Markdown title="Terms of Service" src="terms.md" />} />
                <Route path="/privacy" element={<Markdown title="Privacy Policies" src="privacy.md" />} />

                { /** Logged in pages */ }
                <Route path="/" element={<LoginOnly><Home /></LoginOnly>} />
                <Route path="/home" element={<LoginOnly><Home /></LoginOnly>} />
                <Route path="/scan" element={<LoginOnly><Scan /></LoginOnly>} ></Route>
                <Route path="/mydeck" element={<LoginOnly><MyDeckPage/></LoginOnly>} />
                <Route path="/mydeck/:urlFolderId" element={<LoginOnly><MyDeckPage/></LoginOnly>} />
                <Route path="/connections" element={<LoginOnly><Connections/></LoginOnly>} />
                <Route path="/notifications" element={<LoginOnly><Notifications/></LoginOnly>} />
                <Route path="/account" element={<LoginOnly><Account /></LoginOnly>} />
                <Route path="/myowncards" element={<LoginOnly><MyOwnCards /></LoginOnly>} />
                  
                { /** 404 page */ }
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
          </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
}

export default App;
