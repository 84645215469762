import { Title } from "../../components/Content/Content";
import { CenterViewer } from "../../components/Viewer/Viewer";
import { useRemoveFooter, useTitle } from "../../lib/hooks"
import myDeckStyles from "../../components/MyDeck/MyDeck.module.css";
import DeckCard, { NewDeckCard } from "../../components/Deck/DeckCard";
import { MyDeckPathButton } from "../../components/MyDeck/MyDeck";
import { NewCardPopup } from "../../components/NewCard/NewCard";
import { useEffect, useState } from "react";
import { fetchBackend } from "../../lib/util";
import DeckCardDetails from "../../components/Deck/DeckCardDetails";

export default function MyOwnCards({ ...props }){

    useTitle("My Own Cards");
    useRemoveFooter();
    const styles = {
        ...myDeckStyles,
    }

    const [showingNewCardPopup, setShowingNewCardPopup] = useState(false);
    const [cards, setCards] = useState(null);
    function loadCards(){
        fetchBackend("POST", "card/owned", {})
        .then(res => res.json())
        .then(data => {
            if(!data.error){
                setCards(data.cards);
            }
        });
    
    }
    useEffect(() => {
        if(cards === null){
            loadCards();
        }
    });

    const [showingCardDetails, setShowingCardDetails] = useState(false);
    const [currentCard, setCurrentCard] = useState(null);
    function cardClicked(card){
        setCurrentCard(card);
        setShowingCardDetails(true);
    }

    return (
        <>
            <CenterViewer style={{ maxWidth: "100vw", width: "100vw" }}>
                <Title style={{ marginTop: 0, paddingLeft: 30, color: "#444" }}>
                    <MyDeckPathButton>
                        My Own Cards
                    </MyDeckPathButton>
                </Title>
                <div className={ styles.myDeckContent }>
                    <NewDeckCard 
                        onClick={ () => setShowingNewCardPopup(true) }
                    />
                    { (cards ?? []).map((card, index) => {
                        return <DeckCard
                            clickableMode
                            key={ index }
                            card={ card }
                            onClick={ () => cardClicked(card) }
                            style={{ marginBottom: 30 }}
                        />
                    }) }
                </div>
            </CenterViewer>

            <DeckCardDetails
                card={ currentCard }
                showing={ showingCardDetails }
                toggleFunction={ setShowingCardDetails }
            />

            <NewCardPopup
                owned
                onComplete={ loadCards }
                showing={ showingNewCardPopup }
                toggleFunction={ setShowingNewCardPopup }
            />
        </>
    )
}