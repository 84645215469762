import Card from "../Card/Card";
import { Content, Subtitle } from "../Content/Content";

export default function Construction(){
    return (
        <Card>
            <Subtitle>Under Construction...</Subtitle>
            <Content>This page or functionality is still under construction. If you want to learn more about Eapplico, please go to <a href="/landing">our showcase page</a>. We are also currently hiring; if you are interested in building the future of network management, take a look at our <a href="joinus">Join Us</a> page. </Content>
        </Card>
    )
}