import { useEffect, useState } from "react";

export default function Typing({ words }){

    const wordArray = words ?? [];

    const [currentDisplay, setCurrentDisplay] = useState("");
    const [serial, setSerial] = useState(0);
    // The effect of typing and deleting the words one by one
    // The current word should be wordArray[serial % wordArray.length]
    useEffect(() => {
        const currentWord = wordArray[serial % wordArray.length];
        let currentWordIndex = 0;
        let currentWordDisplay = "";
        let interval2 = null;
        const interval = setInterval(() => {
            if(currentWordIndex < currentWord.length){
                currentWordDisplay += currentWord[currentWordIndex];
                setCurrentDisplay(currentWordDisplay);
                currentWordIndex++;
            } else {
                clearInterval(interval);
                setTimeout(() => {
                    interval2 = setInterval(() => {
                        if(currentWordIndex > 0){
                            currentWordDisplay = currentWordDisplay.slice(0, -1);
                            setCurrentDisplay(currentWordDisplay);
                            currentWordIndex--;
                        } else {
                            clearInterval(interval2);
                            setSerial(serial + 1);
                        }
                    }, 100);
                }, 1000);
            }
        }, 100);
        return () => {
            clearInterval(interval);
            clearInterval(interval2);
        }
    }, [serial, wordArray]);

    return (
        <>{ currentDisplay }<span className="span-cursor"></span></>
    )
}