import Icon from "../Icon/Icon";
import styles from "./Deck.module.css";
import DeckCard, { DeckCardEmpty, DeckCardExample1, DeckCardExample2 } from "./DeckCard";

export default function Deck({ folder, cardOids, selected, myOwnCards, ...props }){

    return (
        <div className={ `${styles.deck} ${selected ? styles.deckSelected : ""}` } {...props}>

            { /* Third Card */ }
            { cardOids?.length >= 3 ? 
                <DeckCard 
                    card={{ Oid: cardOids[2] }} 
                    style={{ position: "absolute", top: 30, transform: "scale(0.95)" }}
                />
            : 
                <DeckCardExample1 
                    style={{ position: "absolute", top: 30, transform: "scale(0.95)" }} 
                />
            }

            { /* Second Card */ }
            { cardOids?.length >= 2 ? 
                <DeckCard 
                    card={{ Oid: cardOids[1] }} 
                    style={{ position: "absolute", top: 60, transform: "scale(0.975)" }}
                />
            : 
                <DeckCardExample2 
                    style={{ position: "absolute", top: 60, transform: "scale(0.975)" }} 
                />
            }

            { /* Card on Top */ }
            { cardOids?.length ? 
                <DeckCard 
                    card={{ Oid: cardOids[0] }} 
                    style={{ position: "absolute", top: 90, transform: "scale(1)" }}
                />
            : 
                <DeckCardEmpty 
                    style={{ position: "absolute", top: 90, transform: "scale(1)" }} 
                />
            }

            <div className={ `${styles.deckCover} glassmorphic` }>
                <h3 className={ styles.deckCoverTitle }>
                    <span className={ styles.deckCoverTitleIndicator }><Icon iconName="checkbox-circle-fill" /></span>
                    { folder?.Name ?? "Untitled Folder" }
                </h3>
                <p className={ styles.deckCoverDescription }>
                    { myOwnCards ? 
                        <>View the cards you created</>
                    : 
                        <>Contains { cardOids?.length ?? 0 } cards</>
                    }
                </p>
            </div>
        </div>
    )
}