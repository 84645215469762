import { Title } from "../../components/Content/Content";
import { CenterViewer } from "../../components/Viewer/Viewer";

export default function NotFound(){
    return (
        <>
            <CenterViewer>
                <Title>
                    That page you were looking for doesn't seem to be here, but we have prepared something special for you!
                </Title>
            </CenterViewer>
        </>
    )
}