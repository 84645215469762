import { useContext, useEffect, useState } from "react";
import styles from "./ShareCard.module.css";
import { fetchBackend } from "../../lib/util";
import { AppContext } from "../AppContext";
import { SelectCard } from "../Form/Form";
import { Button } from "../Button/Button";

export default function ShareCard({ targetUserOid, onComplete, ...props }){
    const appStates = useContext(AppContext);

    const [allCards, setAllCards] = useState(null);
    useEffect(() => {
        if(allCards === null){
            fetchBackend("POST", "card/owned", {})
                .then(data => data.json())
                .then(data => {
                    if(data.error){
                        appStates.addSideNotification("Error fetching your cards. Please try again.");
                        return;
                    }
                    setAllCards(data.cards);
                });
        }
    });

    const [selectedId, setSelectedId] = useState(null);

    function shareCard(){
        if(!selectedId) return;
        fetchBackend("POST", "request/create", {
            CardOid: selectedId,
            TargetUserOid: targetUserOid
        })
        .then(data => data.json())
        .then(data => {
            if(data.error){
                appStates.addSideNotification("Error sharing card. Please try again.");
                return;
            }
            if(onComplete) onComplete();
        });
    }
    
    return (
        (
            <>
                <div style={{ overflowX: "visible" }}>
                    <SelectCard 
                        onChange={ (card) => setSelectedId(card?.Oid ?? null) }
                        selected={ selectedId }
                        cards={ allCards } />
                    { selectedId ? 
                        <Button
                            style={{ width: "100%", position: "sticky", bottom: 15, 
                                boxShadow: "0 0 35px 0 rgba(255,255,255)" }}
                            onClick={ shareCard }
                        >
                            Share this Card
                        </Button>
                    : null }
                </div>
            </>
        )
    )
}