import styles from "./Landing.module.css"
import { CenterViewer } from "../../components/Viewer/Viewer";
import Typing from "../../components/Typing/Typing";
import DeckCard, { DeckCardExample1 } from "../../components/Deck/DeckCard";
import FloatingChips, { FloatingChip } from "../../components/Artistic/FloatingChips";
import { AppStoreButton } from "../../components/Button/Button";
import ShowcaseScreens from "../../components/Artistic/ShowcaseScreens";
import SearchDemo from "../../components/Artistic/SearchDemo";
import AnimateOnScroll from "../../components/Animations/AppearOnScroll";
import SharePhone from "../../components/Artistic/SharePhone";

export default function Landing(){
    
    return (
        <>
            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0, overflowY: "visible" }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <h1 className={ styles.landingTitle }>
                                <span className="emphasis">
                                    <Typing words={["Organize", "Scan", "Manage", "Sort", "Search"]} />
                                </span>&nbsp;Contacts,
                                <br/>
                                Stay Connected.  
                            </h1>
                            <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                World's only AI-powered contact management platform that puts your business cards and phone contacts in one place.
                            </p>
                            <AppStoreButton />
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>
                                <AnimateOnScroll
                                    offset={ 0.5 }
                                    // originalStyle={{ transform: "scale(1.3)", transition: "all 1s" }}
                                    // activatedStyle={{ transform: "scale(1)" }}
                                >
                                    {/* <DeckCardExample1
                                        style={{ marginTop: 170 }}
                                    /> */}
                                    <SearchDemo />

                                </AnimateOnScroll>
                                <div className={ styles.landingRadiation }>
                                    <div className={ styles.landingRadiationOverlay }></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <div style={{ width: "100%", height: 200 }}></div>

            
            <div className={ `${styles.landingSection} ${styles.landingSectionFirst} ${styles.landingSectionSecond}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0, marginTop: 200, }}>
                    <div className={ styles.landingSectionFirst } style={{  }}>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle } style={{ textAlign: "center" }}>
                                    World's Most Advanced Contact Search Engine
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17, textAlign: "center" }} >
                                    Never lose a contact again. Eapplico finds the right person with only a few vague keywords, all in a few seconds. 
                                </p>
                            </AnimateOnScroll>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ maxWidth: 1000, paddingBottom: 0, overflow: "visible" }}>
                <AnimateOnScroll>
                    <ShowcaseScreens />
                </AnimateOnScroll>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst} ${styles.landingSectionThird}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Business Cards? We Digitize Them for You.
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Often losing your contacts? Eapplico's advanced business card scanner instantly digitizes all your business card.
                                </p>
                            </AnimateOnScroll>

                            <div className={ styles.landingRadiation }>
                                <div className={ `${styles.landingRadiationOverlay} ${styles.landingRadiationOverlaySmaller}` }></div>
                            </div>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ `${styles.landingFirstRightPerson} ${styles.landingFirstRightSearch}` }>
                                <AnimateOnScroll
                                    originalStyle={{ transform: "scale(1.3)", transition: "all 1s" }}
                                    activatedStyle={{ transform: "scale(1)" }}
                                >
                                    <DeckCardExample1 />
                                </AnimateOnScroll>
                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ maxWidth: 600, padding: 0, marginTop: -100 }}>
                <AnimateOnScroll
                    originalStyle={{ transform: "translateY(100px)", transition: "all 1s" }}
                    activatedStyle={{ transform: "scale(1)" }}
                >
                    <FloatingChips />
                </AnimateOnScroll>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst} ${styles.landingSectionSecond}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Organize Your Connections with Utmost Simplicity
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Most connection platforms, including LinkedIn™, make your contacts hard to find. Eapplico makes it easy.
                                </p>
                            </AnimateOnScroll>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>
                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ maxWidth: 1000, padding: 0, paddingTop: -40, overflow: "visible" }}>
                <AnimateOnScroll>
                    <ShowcaseScreens image="/img/content/mydeck.png" />
                </AnimateOnScroll>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle } style={{ textAlign: "center" }}>
                                    Card Sharing Right at Your Fingertip.
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17, textAlign: "center" }}>
                                    Forgot to bring your business cards? With Eapplico, you can share your card digitally. 
                                </p>
                            </AnimateOnScroll>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ maxWidth: 700, padding: 0, marginTop: -20, overflow: "visible" }}>
                <AnimateOnScroll>
                    <SharePhone />
                </AnimateOnScroll>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Sign Up Now!
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    What are you waiting for? Sign up and manage your cards like never before. It's completely free! 
                                </p>
                            </AnimateOnScroll>
                            <AppStoreButton />
                        </div>
                    </div>
                </CenterViewer>
            </div>
        </>
    )
}