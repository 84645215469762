import aboutStyles from "./About.module.css"
import landingStyles from "../Landing/Landing.module.css"
import { CenterViewer } from "../../components/Viewer/Viewer"
import LogoBand from "../../components/LogoBand/LogoBand"
import { DeckCardExample1 } from "../../components/Deck/DeckCard"
import Quotation from "../../components/Artistic/Quatation"
import Radiation from "../../components/Artistic/Radiation"
import Card, { GlassmorphicCard } from "../../components/Card/Card"
import TeamMemberCard from "../../components/TeamMember/TeamMember"
import { useTitle } from "../../lib/hooks"
import AnimateOnScroll from "../../components/Animations/AppearOnScroll"
import { Button } from "../../components/Button/Button"

export default function About({}){
    useTitle("About")
    const styles = { ...aboutStyles, ...landingStyles }

    return (
        <>
            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <h1 className={ styles.landingTitle }>
                                Building the Professional Networking Platform of Tomorrow, Today.
                            </h1>
                            <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                We are shaping the future of professional network management and business card exchange.
                            </p>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>
                                <AnimateOnScroll
                                    offset={ 0.5 }
                                    originalStyle={{ transform: "scale(1.3)", transition: "all 1s" }}
                                    activatedStyle={{ transform: "scale(1)" }}
                                >
                                    <DeckCardExample1
                                        style={{ marginTop: 170 }}
                                    />
                                </AnimateOnScroll>
                                <div className={ styles.landingRadiation }>
                                    <div className={ styles.landingRadiationOverlay }></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ maxWidth: 1000 , transform: "translateY(50px)"}}>
                <AnimateOnScroll>
                    <Quotation source="Founder & CEO, Spencer Huang">
                        We care about empowering people just like you and me, because everyone has the potential to make a difference in this world. 
                    </Quotation>
                </AnimateOnScroll>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst} ${styles.landingSectionSecond}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Founded by Passionate Student Entrepreneurs.
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Eapplico is founded by a group of students at top universities around the world who are experienced in saas startup development.
                                </p>
                            </AnimateOnScroll>
                        </div>
                        <div className={ styles.landingFirstRight } style={{ height: 5 }}>
                            <div className={ styles.landingFirstRightPerson }>

                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <AnimateOnScroll>
                <LogoBand
                    // grayscale
                    footnote="* No official affiliation with the institutions listed."
                    maxWidth={ 1200 }
                    logos={[
                        { src: "/img/schools/duke.svg", alt: "Duke University", size: "75%" },
                        { src: "/img/schools/penn-state.png", alt: "Pensylvenia State University", size: "80%" },
                        { src: "/img/schools/ntu.png", alt: "National Taiwan University", size: "80%" },
                        { src: "/img/schools/ucla.svg", alt: "UCLA", size: "65%" },
                        { src: "/img/schools/changgeng.png", alt: "Chang Geng University", size: "65%" },
                        { src: "/img/schools/nyu.svg", alt: "NYU", size: "70%" },
                        { src: "/img/schools/usc.png", alt: "USC", size: "75%" },
                    ]}
                />
            </AnimateOnScroll>

            <CenterViewer style={{ maxWidth: 1200, padding: 0 }}>
            <Radiation size={ 1500 } style={{ marginTop: 200, marginBottom: -1600 }} />
                <div className={ styles.aboutNameCardContainer }>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/spencer.jpg"
                            name="Spencer Huang" 
                            role="Founder, CEO"
                            description="Spencer is a senior studying CS at Penn State, specializing in graphics procesing and business. Besides his background in competitive programming and robotics, he is passionate for startup ventures. He exemplifies a unique blend of entrepreneurial spirit and a commitment for technical innovation. Driven to lead Eapplico, he is in charge of software architecture designs and business strategies."
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/bernie.png"
                            name="Bernie Chen" 
                            role="Co-Founder, CTO, CV Engineer"
                            description={ <>Bernie is Taiwanese sophomore at Duke University studying ECE and CS and concentrating in Machine Learning. At Eapplico, he works on the computer vision systems. His past experience includes researching and implementing convolutional neural networks, recurrent neural networks, and generative diffusion models. He also has experience in full stack development and enjoys swimming & tennis.</> } 
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/neil.jpg" 
                            name="Neil Lu" 
                            role="Co-Founder, COO, Systems Architect"
                            description={ <>Neil is a student in ECE and CS at Duke University. He is experienced in full-stack engineering, DevOps, natural language processing, and startup leadership, and is responsible for integrating the software systems at Eapplico. Back in Taiwan, he founded ed-tech startup <a href="https://zetria.tw">Zetria</a>, a platform with over 1 million views, and <a href="https://infvolunteer.org">Infinitas</a>, a volunteer matching platform that has 4,000+ hours of 1-on-1 tutoring. </>} 
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/claire.png"
                            name="Claire Chang" 
                            role="Head of Business"
                            description="Claire is a student at the University of Southern California (USC), where she double majors in Accounting and Business Administration with a minor in Artificial Intelligence Applications. She has a strong passion for puzzles, escape rooms, and all forms of problem-solving. At Eapplico, Claire spearheads the business operations, leading the business team and driving strategic initiatives to enhance performance and innovation." 
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/chiwei.jpg"
                            name="Chiwei" 
                            role="Co-Founder, iOS Engineer"
                            description="Chi-Wei is a sophomore studying Computer Science and Accounting at UCLA, specializing in web development and data science. He enjoys participating in community projects and startup ventures that positively impact the world using innovative technology. At Eapplico, he works on the frontend iOS client and enjoys navigating through interesting software engineering problems with the Eapplico team." 
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/mark.jpg"
                            name="Mark Chiang" 
                            role="Co-Founder, CMO, iOS Engineer"
                            description="Mark is a Taiwanese sophomore at Chang Gung University, specializing in Artificial Intelligence. At Eapplico, he contributes to the iOS client. His past experience includes natural language processing, statistical analysis, and training Large Language Models for automated code generation. Beyond academics, he enjoys traveling and playing table tennis." 
                        />
                    </AnimateOnScroll>
                    <AnimateOnScroll>
                        <TeamMemberCard 
                            src="/img/members/jonathan.jpg"
                            name="Jonathan Shieh" 
                            role="Co-Founder, iOS Engineer"
                            description="Jonathan is a Taiwanese sophomore at National Taiwan University studying Computer Science. At Eapplico, he works on the frontend iOS client. He is experienced in network administration, robotics, statistical analysis, and full stack development. He also enjoys various sports and games." 
                        />
                    </AnimateOnScroll>
                </div>
            </CenterViewer>

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Let's Get in Touch!
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Got a business idea? Want to join our team? We'd love to hear from you! Anyone is welcome!
                                </p>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <a href="mailto:service@eapplico.com">
                                    <Button>
                                        service@eapplico.com
                                    </Button>
                                </a>
                            </AnimateOnScroll>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>

                            </div>
                        </div>
                    </div>
                </CenterViewer>

            </div>

        </>
    )
}