import styles from "./Icon.module.css"

export default function Icon({ iconName, ...props }){
    return (
        <i className={ `${styles.icon} ri-${iconName}` } 
        {...props}></i>
    )
}

export function IconTextPair({ iconName, children, ...props }){
    return (
        <div>
            <Icon iconName={ iconName } {...props} />&nbsp;&nbsp;
            <span>{ children }</span>
        </div>
    )
}

export function IconPlaceholder({ children, iconName, ...props }){
    return (
        <div className={ styles.iconPlaceholder }>
            <div className={ styles.iconPlaceholderIcon }>
                <Icon iconName={ iconName } style={{ fontSize: 45 }} />
            </div>
            <div className={ styles.iconPlaceholderContent }>
                { children }
            </div>
        </div>
    )
}