import { useEffect, useState } from "react";
import styles from "./Viewer.module.css"
import ProgressBar from "../ProgressBar/ProgressBar";

export function CenterViewer({ children, ...props }) {
    return <div className={ styles.centerViewer } {...props}>
        { children }
    </div>
}

export function TabsViewer({ tabs, tab, onChange, noContent, sticky, ...props }){
    
    const [currentTab, setCurrentTab] = useState(tab ?? 0);
    useEffect(() => {
        setCurrentTab(tab ?? 0);
    }, [tab]);

    function updateTab(index){
        setCurrentTab(index);
        if(onChange) onChange(index);
        updateQueryField("tab", index);
    }

    function updateQueryField(fieldName, fieldValue) {
        
    }

    return (
        <>
            <div className={ `
                    ${styles.tabsTop}
                    ${sticky ? styles.tabsTopSticky : ""}    
                ` } {...props} 
            >
                { tabs.map((tab, index) => {
                    if(!tab) return;
                    return <div 
                        className={ `${styles.tab} ` + 
                        (index === currentTab ? styles.currentTab : "" ) } 
                        key={ `tab-${index}` } 
                        onClick={ () => updateTab(index) }
                    >    
                        { tab.name }
                    </div>
                }) }
            </div>
            { !noContent ? 
                <div className={ styles.tabsBody }>
                    { tabs[currentTab].content }
                </div>
            : null }
        </>
    );
}

export function ProgressViewer({ steps, currentStep, ...props }){
    return <>
        <div style={{ height: 80 }}>
            <h3 style={{ margin: "0 0 5px", fontFamily: "var(--font-family)", 
                    color: "var(--theme-color)", paddingLeft: 5 }}>
                { steps[currentStep ?? 0]?.name ?? <></> }
            </h3>
            <ProgressBar progress={ steps?.length ? (currentStep+1) / steps.length : 1 } />
        </div>
        <div style={{ height: "calc(100% - 80px)", overflowY: "auto", width: "100%" }}>
            { steps[currentStep ?? 0]?.content ?? <></> }
        </div>
    </>
}