import { useEffect, useRef, useState } from "react";
import styles from "./AnimateOnScroll.module.css";

export default function AnimateOnScroll({ children, originalStyle, activatedStyle, offset, ...props }){
    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting){
                    setIsIntersecting(true);
                    observer.disconnect();
                }
            },
            { rootMargin: `0px 0px -${window.screen.height*(offset ?? 0.2)}px 0px` }
        );
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    const style1 = {
        transition: "all 1s",
        opacity: 0,
        ...originalStyle
    }

    const style2 = {
        ...style1,
        ...activatedStyle,
        opacity: 1,
    }

    return (
        <div ref={ ref } style={ isIntersecting ? style2 : style1 }>
            { children }
        </div>
    )
}