import joinStyles from "./JoinUs.module.css";
import landingStyles from "../Landing/Landing.module.css";
import { useTitle } from "../../lib/hooks";
import { CenterViewer } from "../../components/Viewer/Viewer"
import LogoBand from "../../components/LogoBand/LogoBand"
import { DeckCardExample1 } from "../../components/Deck/DeckCard"
import Quotation from "../../components/Artistic/Quatation"
import Radiation from "../../components/Artistic/Radiation"
import Card, { GlassmorphicCard } from "../../components/Card/Card"
import TeamMemberCard from "../../components/TeamMember/TeamMember"
import AnimateOnScroll from "../../components/Animations/AppearOnScroll"
import JobPostItem from "../../components/JobPost/JobPostItem";
import { Subtitle, Title } from "../../components/Content/Content";
import { Button } from "../../components/Button/Button";

export default function JoinUs(){
    useTitle("Join Us");
    const styles = {
        ...joinStyles,
        ...landingStyles
    }

    return (
        <>
            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <h1 className={ styles.landingTitle }>
                                We are looking for someone like you!
                            </h1>
                            <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                Join us in shaping the future of professional network management and business card exchange.
                            </p>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>
                                <AnimateOnScroll
                                    offset={ 0.5 }
                                    originalStyle={{ transform: "scale(1.3)", transition: "all 1s" }}
                                    activatedStyle={{ transform: "scale(1)" }}
                                >
                                    <DeckCardExample1
                                        style={{ marginTop: 170 }}
                                    />
                                </AnimateOnScroll>
                                <div className={ styles.landingRadiation }>
                                    <div className={ styles.landingRadiationOverlay }></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <AnimateOnScroll>
                <LogoBand
                    // grayscale
                    footnote="* No official affiliation with the institutions listed."
                    maxWidth={ 1200 }
                    logos={[
                        { src: "/img/schools/duke.svg", alt: "Duke University", size: "75%" },
                        { src: "/img/schools/penn-state.png", alt: "Pensylvenia State University", size: "80%" },
                        { src: "/img/schools/ntu.png", alt: "National Taiwan University", size: "80%" },
                        { src: "/img/schools/ucla.svg", alt: "UCLA", size: "65%" },
                        { src: "/img/schools/changgeng.png", alt: "Chang Geng University", size: "65%" },
                        { src: "/img/schools/nyu.svg", alt: "NYU", size: "70%" },
                        { src: "/img/schools/usc.png", alt: "USC", size: "75%" },
                    ]}
                />
            </AnimateOnScroll>

            <div style={{ width: "100%", height: "20", marginBottom: -100 }}></div>
            {/* <CenterViewer style={{ maxWidth: 1000 , transform: "translateY(50px)"}}>
                <AnimateOnScroll>
                    <Quotation source="Founder & CEO, Spencer Huang">
                        We care about empowering people just like you and me, because everyone has the potential to make a difference in this world. 
                    </Quotation>
                </AnimateOnScroll>
            </CenterViewer> */}

            <div className={ `${styles.landingSection} ${styles.landingSectionFirst} ${styles.landingSectionSecond}` }>
                <CenterViewer style={{ maxWidth: 1200, padding: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    World Class Team
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Eapplico's team consists of talented individuals from around the world who are experienced in startups and possess strongly competent skills and educational backgrounds.
                                </p>
                            </AnimateOnScroll>
                        </div>
                        <div className={ styles.landingFirstRight } style={{ height: 5 }}>
                            <div className={ styles.landingFirstRightPerson }>

                            </div>
                        </div>
                    </div>
                </CenterViewer>
            </div>

            <CenterViewer style={{ padding: 0, marginTop: -20 }}>
                <AnimateOnScroll>
                    <Title style={{ textAlign: "center" }}>Openings</Title>
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="Financial Planner"
                        description="This position is responsible for creating and executing financial strategies, including budgeting, forecasting, and financial analysis."
                        /> 
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="SEO Content Creator"
                        description="This position is responsible for creating content that is optimized for search engines and is both engaging and informative."
                        /> 
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="UI&UX Designer"
                        description="This position is responsible for designing the user experience and branding, and communicating designs between the development teams and the marketing team."
                        /> 
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="Marketing Specialist"
                        description="This position is responsible for creating and executing marketing strategies, including social media, email marketing, content creation, and more."
                    /> 
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="Engineer, Image Processing"
                        description="This position is responsible for engineering image processing algorithms and developing image processing software for our Python backend. "
                    /> 
                </AnimateOnScroll>
                <AnimateOnScroll>
                    <JobPostItem
                        title="Engineer, Android"
                        description="This position is responsible for developing the Android version of our mobile application."
                    /> 
                </AnimateOnScroll>
            </CenterViewer>


            <div className={ `${styles.landingSection} ${styles.landingSectionFirst}` }>
                <CenterViewer style={{ maxWidth: 1200, paddingBottom: 0 }}>
                    <div className={ styles.landingSectionFirst }>
                        <div className={ styles.landingFirstLeft }>
                            <div className={ styles.landingTitleLogo }>
                            </div>
                            <AnimateOnScroll>
                                <h1 className={ styles.landingTitle }>
                                    Let's Get in Touch!
                                </h1>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <p className={ styles.landingDescription } style={{ marginBottom: 17 }}>
                                    Got a business idea? Want to join our team? We'd love to hear from you! Anyone is welcome!
                                </p>
                            </AnimateOnScroll>
                            <AnimateOnScroll>
                                <a href="mailto:service@eapplico.com">
                                    <Button>
                                        service@eapplico.com
                                    </Button>
                                </a>
                            </AnimateOnScroll>
                        </div>
                        <div className={ styles.landingFirstRight }>
                            <div className={ styles.landingFirstRightPerson }>

                            </div>
                        </div>
                    </div>
                </CenterViewer>

            </div>

        </>
    )
}