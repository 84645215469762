import { GlassmorphicCard } from "../Card/Card";
import styles from "./TeamMember.module.css"

export default function TeamMemberCard({ name, description, role, src, ...props }){
    return (
        <GlassmorphicCard style={{ width: 360, height: 600 }} {...props}>
            <div className={ styles.teamMemberCardPhoto } style={{ backgroundImage: `url(${src})` }}>

            </div>
            <h3 className={ styles.teamMemberCardTitle }>{ name }</h3>

            <p className={ styles.teamMemberCardRole }>
                { role }
            </p>
            <p className={ styles.teamMemberCardDescription }>
                { description }
            </p>
        </GlassmorphicCard>
    )
}