import { useContext, useState } from "react";
import { BACKEND_URL } from "../../lib/config";
import styles from "./Avatar.module.css";
import Popup from "../Popup/Popup";
import { AppContext } from "../AppContext";
import { Input } from "../Form/Form";
import { Button } from "../Button/Button";
import Icon from "../Icon/Icon";

export default function Avatar({ username, editable, size, ...props }){
    const appStates = useContext(AppContext);
    
    const propStyles = {
        ...props.style,
        backgroundImage: `url(${BACKEND_URL}user/profile/${username})`,
        width: size,
        height: size
    }

    const [showingUpdate, setShowingUpdate] = useState(false);

    function submitFormData(){
        const formData = new FormData();
        formData.append("image", document.querySelector("#avatar-upload").files[0]);
        formData.append("token", localStorage.getItem("userToken"))
        appStates.addSideNotification("Uploading...")
        fetch(`${BACKEND_URL}user/updateProfile/${appStates.user?.Username}`, {
            method: "PUT",
            body: formData
        })
        .then(res => res.json())
        .then(res => {
            if (!res.error){
                appStates.addSideNotification("Profile updated successfully!");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }else{
                appStates.addSideNotification("The file is too large. Please upload a file smaller than 1MB.")
            }
        })
    }

    return (
        <>
            <div className={ styles.avatar } {...props} style={ propStyles } >
                { editable && <button className={ styles.editable } onClick={ () => setShowingUpdate(true) }>
                    Edit
                </button> }
                { !username ? 
                    <div className={ styles.avatarPlaceholder }>
                        <Icon iconName="user-3-fill" style={{ fontSize: (size ?? 100) / 2.5 }} />
                    </div>
                : null }
            </div>
            <Popup
                showing={ showingUpdate }
                toggleFunction={ setShowingUpdate }
                size="small"
                title="Update Avatar"
            >
                <Input
                    id="avatar-upload"
                    type="file"
                    label="Upload Photo" 
                    style={{ marginBottom: 15 }} 
                />
                <Button onClick={ submitFormData }>Confirm Upload</Button>
            </Popup>
        </>
    )
}