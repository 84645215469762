import styles from "./ProgressBar.module.css";

export default function ProgressBar({ progress, showPercentage, ...props }){
    return (
        <>
            { showPercentage ? 
                <span className={ styles.progressPercentage }>
                    {Math.round(progress*100)}%
                </span>
            : null }
            <div className={ styles.progressBar } {...props}>
                <div 
                    className={ styles.progressBarOverlay }
                    style={{ width: `${(1-(progress ?? 0))*100}%` }}
                >

                </div>
            </div>
        </>
    )
}