import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function PageChangeTrigger({ trigger }){

    const { pathname } = useLocation();

    useEffect(() => {
        trigger && trigger();
    }, [pathname]);
    return(
        <>
        </>
    )
}