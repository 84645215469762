import { useContext, useEffect, useState } from 'react';
import { Button, ForwardButton } from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import { Content, Subtitle, Title } from '../../components/Content/Content';
import Flex from '../../components/Flex/Flex';
import { Input } from '../../components/Form/Form';
import { CenterViewer, TabsViewer } from '../../components/Viewer/Viewer';
import styles from './Account.module.css';
import { AppContext } from '../../components/AppContext';
import { fetchBackend } from '../../lib/util';
import { useTitle } from '../../lib/hooks';
import Avatar from '../../components/Avatar/Avatar';
import DeckCard from '../../components/Deck/DeckCard';
import DeckCardDetails from '../../components/Deck/DeckCardDetails';
import { useNavigate } from 'react-router-dom';

export default function Account() {
    useTitle("Account")
    const appStates = useContext(AppContext);
    const navigate = useNavigate();

    const publicFields = ["FirstName", "MiddleName", "LastName", "PublicEmail", 
                        "Phone", "Company", "Position", "Headline", "Address"];
    const [user, setUser] = useState(Object.assign({}, appStates.user));
    useEffect(() => {
        setUser(Object.assign({}, appStates.user));
    }, [appStates.user]);
    function udpatedUser(key, value) {
        setUser(Object.assign({}, user, { [key]: value }));
    }

    function saveFields(fields){
        const toUpdate = {};
        fields.forEach(field => {
            toUpdate[field] = user[field];
        });
        appStates.addSideNotification("Updating profile...")
        fetchBackend("PUT", `/user/update/${user.Username}`, {
            user: toUpdate
        })
        .then(res => res.json())
        .then(res => {
            if(!res.error){
                appStates.initUser();
                appStates.addSideNotification("Profile updated successfully!");
            }
        });
    }

    const [cards, setCards] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [showingCardDetails, setShowingCardDetails] = useState(false);
    function loadCards(){
        fetchBackend("POST", "card/owned", {})
        .then(res => res.json())
        .then(data => {
            if(!data.error){
                setCards(data.cards);
            }
        });
    }
    useEffect(() => {
        if(cards === null){
            loadCards();
        }
    })
    function cardClicked(card){
        setSelectedCard(card);
        setShowingCardDetails(true);
    }

    return (
        <>
            <DeckCardDetails 
                card={ selectedCard }
                toggleFunction={ () => setShowingCardDetails(false) }
                showing={ showingCardDetails }
            />
            <CenterViewer>
                <Title>My Profile</Title>
                <Card>
                    <Subtitle>Hello, { appStates.user?.FirstName }</Subtitle>
                </Card>
                <Flex gap={ 15 } style={{ marginTop: 15 }}>
                    <Card style={{ flex: 1 }}>
                        <Subtitle>Statistics</Subtitle>
                        <Flex gap={ 5 }>

                        </Flex>
                    </Card>
                    <Card style={{ flex: 1 }}>
                        <Subtitle>My Own Cards</Subtitle>
                        <div className={ styles.cards } >
                            <div style={{ 
                                width: "var(--deck-card-width)", 
                                height: cards?.length ? 220 + 30 * (cards.length-1) : 220 
                            }} />
                            { cards?.map((card, index) => {
                                if(index > 2) return null;
                                return <DeckCard clickableMode
                                    style={{ position: "absolute", top: index * 30, 
                                        transform: `scale(${1 - 0.025 * (cards.length - index)})` }}
                                    key={ `account-card-${index}` }
                                    card={ card }
                                    onClick={ () => cardClicked(card) }
                                />
                            } )}
                        </div>
                        <ForwardButton 
                            onClick={ () => navigate("/myowncards") }
                            style={{ margin: "0 auto" }}>
                            View All
                        </ForwardButton>
                    </Card>
                </Flex>
                
                <hr />

                <Title>Settings</Title>
                <TabsViewer sticky tabs={[
                    { name: "Public Information", content: <>
                        <Content style={{ paddingBottom: 10 }}>
                            Information in this section is public and can be viewed by anyone with an Eapplico account. Updates will not be reflected in your business cards unless you update them.
                        </Content>
                        <Avatar editable
                            username={ appStates.user?.Username } 
                            size={ 170 }
                            style={{ marginBottom: 30 }}
                        />
                        <Input 
                            label="First Name" 
                            onChange={ e => udpatedUser("FirstName", e.target.value) }
                            defaultValue={ user?.FirstName }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Middle Name" 
                            onChange={ e => udpatedUser("MiddleName", e.target.value) }
                            defaultValue={ user?.MiddleName }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Last Name" 
                            onChange={ e => udpatedUser("LastName", e.target.value) }
                            defaultValue={ user?.LastName }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Public Email" 
                            onChange={ e => udpatedUser("PublicEmail", e.target.value) }
                            defaultValue={ user?.PublicEmail }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Phone Number" 
                            onChange={ e => udpatedUser("Phone", e.target.value) }
                            defaultValue={ user?.Phone }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Company" 
                            onChange={ e => udpatedUser("Company", e.target.value) }
                            defaultValue={ user?.Company }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Position" 
                            onChange={ e => udpatedUser("Position", e.target.value) }
                            defaultValue={ user?.Position }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Headline" 
                            onChange={ e => udpatedUser("Headline", e.target.value) }
                            defaultValue={ user?.Headline }
                            style={{ marginBottom: 15 }} 
                        />
                        <Input 
                            label="Address" 
                            onChange={ e => udpatedUser("Address", e.target.value) }
                            defaultValue={ user?.Address }
                            style={{ marginBottom: 15 }} 
                        />
                        <Button 
                            style={{ marginTop: 20 }}
                            onClick={ () => saveFields(publicFields) }
                        >
                            Save Updates
                        </Button>
                    </> },
                    { name: "Account Details", content: <>
                    
                    </> },
                    { name: "Accessibility", content: <>
                    
                    </> }
                ]} />
            </CenterViewer>
        </>
    );
}