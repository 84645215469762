import Construction from "../../components/Construction/Construction";
import { CenterViewer } from "../../components/Viewer/Viewer";

export default function Investors(){
    return (
        <>
            <CenterViewer>
                <Construction />
            </CenterViewer>
        </>
    )
}