import { useContext, useEffect, useRef, useState } from "react";
import styles from "./AISearch.module.css";
import { AppContext } from "../AppContext";
import { Input, TextArea } from "../Form/Form";
import Icon, { IconPlaceholder } from "../Icon/Icon";
import { fetchBackend } from "../../lib/util";
import { CenterViewer } from "../Viewer/Viewer";
import { IconTextButton } from "../Button/Button";
import AnimateOnScroll from "../Animations/AppearOnScroll";
import DeckCard from "../Deck/DeckCard";
import { Subtitle, Title } from "../Content/Content";
import myDeckStyles from "../../components/MyDeck/MyDeck.module.css";
import DeckCardDetailsPopup from "../Deck/DeckCardDetails";

export default function AISearch({ onToggleFunction, ...props }){
    const appStates = useContext(AppContext);
    function backdropClicked(e){
        if(e.target === e.currentTarget && !enteredSearchMode){
            appStates.closeAISearch();
        }
    }
    function search(query){
        if(query === "" || query == lastSearchedQuery) return;
        setLastSearchedQuery(query);
        setResultCards(null);
        setErrorMessage(null);
        fetchBackend("POST", "search/cards", { query })
            .then(data => data.json())
            .then(data => {
                if(data.error){
                    appStates.addSideNotification("Error searching. Please try again.");
                    setErrorMessage(data.error);
                    return;
                }
                setResultCards(data.cards);
                setErrorMessage(null);
            });
        setEnteredSearchMode(true);
        // setTimeout(() => {
            setMoveSearchBar(true);
        // }, 600);
    }

    const [enteredSearchMode, setEnteredSearchMode] = useState(false);
    const [moveSearchBar, setMoveSearchBar] = useState(false);
    const [lastSearchedQuery, setLastSearchedQuery] = useState("");
    const [resultCards, setResultCards] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const [showingDeckCardDetails, setShowingDeckCardDetails] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    function cardClicked(e, card){
        if(e.detail === 2){
            setSelectedCard(card);
            setShowingDeckCardDetails(true);
        }
    }

    return (
        <>
        
            <div className={ `${styles.backdrop} 
                ${enteredSearchMode ? styles.backdropSearchMode : ""}` } 
                onClick={ backdropClicked } 
            >
                <AISearchBar
                    moveSearchBar={ moveSearchBar }
                    onToggleFunction={ onToggleFunction }
                    style={{ position: "relative", top: moveSearchBar ? 50 : "40%", 
                        left: "50%", transform: moveSearchBar ? "translateX(-50%)" : "translate(-50%, -50%)" }}
                    search={ search }
                />
                { enteredSearchMode ? 
                    <>
                        <CenterViewer style={{ maxWidth: 700, paddingTop: 5, paddingBottom: 0, margin: "50px auto 0" }}>
                            <IconTextButton iconName="close-line"
                                onClick={ onToggleFunction }
                            >
                                Exit search mode
                            </IconTextButton>
                        </CenterViewer>
                        <CenterViewer style={{ maxWidth: 1300, paddingTop: 5, marginTop: 0 }}>
                            <hr style={{ margin: "30px 0" }} />
                            <Title>Most Relevant</Title>
                            { resultCards === null ? 
                                errorMessage === "query.bad" ? 
                                    <IconPlaceholder iconName="emotion-sad-line">
                                        Try entering something more descriptive
                                    </IconPlaceholder>
                                : 
                                    <IconPlaceholder iconName="menu-search-line">
                                        Searching...
                                    </IconPlaceholder>
                            : resultCards?.filter(card => card.Score >= 0.5).length == 0 ? 
                                <IconPlaceholder iconName="emotion-sad-line">
                                    Nothing here
                                </IconPlaceholder>
                            : <div className={ myDeckStyles.myDeckContent } style={{ width: "100%" }} >
                                {resultCards?.filter(card => card.Score >= 0.5).map((card, index) => {
                                    return (
                                        <AnimateOnScroll key={ `${card.Oid}-${index}` }>
                                            <DeckCard
                                                onClick={ e => cardClicked(e, card) }
                                                card={ card }
                                                style={{ margin: "10px auto 45px" }}
                                                functionalMode
                                            />
                                        </AnimateOnScroll>
                                    )
                                })}
                            </div> }
                            <hr style={{ margin: "30px 0" }} />
                            <Title>Less Relevant</Title>
                            { resultCards?.filter(card => card.Score < 0.5).length == 0 ? 
                                <IconPlaceholder iconName="emotion-sad-line">
                                    Nothing here
                                </IconPlaceholder>
                            : <div className={ myDeckStyles.myDeckContent } style={{ width: "100%" }}>
                                {resultCards?.filter(card => card.Score < 0.5).map((card, index) => {
                                    return (
                                        <AnimateOnScroll key={ `${card.Oid}-${index}` }>
                                            <DeckCard
                                                onClick={ e => cardClicked(e, card) }
                                                card={ card }
                                                style={{ margin: "10px auto 45px" }}
                                                functionalMode
                                            />
                                        </AnimateOnScroll>
                                    )
                                })}
                            </div> }
                        </CenterViewer>
                        <div style={{ width: "100%", height: 100 }}></div>
                    </>
                : null }
            </div>
            <DeckCardDetailsPopup 
                baseProps={{ style: { zIndex: 101 } }}
                noShare
                toggleFunction={ setShowingDeckCardDetails}
                showing={ showingDeckCardDetails }
                card={ selectedCard }
            />
        </>
    )
}

export function AISearchBar({ onToggleFunction, search, moveSearchBar, ...props }){
    const textAreaRef = useRef(null); // Create a ref for the TextArea

    // Adjust the height of the textarea
    const adjustHeight = () => {
        const textArea = textAreaRef.current;
        if (textArea) {
            if (textArea.value == "") textArea.style.height = "80px";
            else textArea.style.height = `${textArea.scrollHeight}px`; 
            // In case you have a limitation
            // textArea.style.height = `${Math.min(textArea.scrollHeight, limit)}px`;
        }
    };

    // Adjust the height on keydown
    function onKeyDown(e){ 
        adjustHeight(); 
        if(e.key === "Enter") {
            e.preventDefault();
            goSearch();
        }
        if(e.key === "Escape") {
            onToggleFunction && onToggleFunction();
        }
    }

    // Adjust the height when the component mounts
    useEffect(() => {
        adjustHeight();
        textAreaRef.current.focus();
    }, []);

    function goSearch(){
        search(textAreaRef.current.value);
    }

    return (
        <div className={ `${styles.searchBar} ${moveSearchBar ? styles.searchBarMoved : ""}` } 
        {...props}>
            <TextArea ref={ textAreaRef }
                placeholder={ "man in tech, met in NYC, ..." }
                onKeyDown={ onKeyDown }
                style={{ border: "none", padding: 20, width: "calc(100% - 80px)",
                    fontSize: `calc(var(--font-size)/6)`, resize: "none", borderRadius: 20 }}
            type="text" />
            <div>
                <button className={ styles.searchBarButton }
                    onClick={ goSearch }
                >
                    <Icon 
                        iconName={ "search-line" } 
                        style={{ color: "white", fontSize: 30 }} 
                    />
                </button>
            </div>
        </div>
    )
}