import { useEffect, useRef, useState } from "react";

export default function PerspectiveCrop({ originalImage, initialPosition, onComplete }){

    /**
     * @param {string} originalImage - The image to be cropped, as a base64 string.
     * @param {object} initialPosition - The initial position of the crop, as an object 
     * formatted as follows: { topLeft: { x: 0, y: 0 }, topRight: { x: 0, y: 0 },
     *                          bottomLeft: { x: 0, y: 0 }, bottomRight: { x: 0, y: 0 } }
     * @param {function} onComplete - The function to be called when the crop is completed.
     * The first argument is the cropped image, as a base64 string.
     * 
     * This component is built for React Web, but the coding style is adjusted to match
     * that of React Native. This makes it easier to convert the component to React Native.
     * 
     * When converting, you might want to replace the component names first. For example:
     * - Changing <div /> to <View />
     * - Changing <Button /> to <TouchableOpacity />
     * 
     * Then, you might want to adjust the styles, which is already written as a JavaScript
     * object with field names mapping to different set of styles. 
     * 
     */

    const [imageAspectRatio, setImageAspectRatio] = useState(1); // Width / Height
    const [imageElementWidth, setImageElementWidth] = useState(0);
    const [imageElementHeight, setImageElementHeight] = useState(0);
    const [vertexBounds, setVertexBounds] = useState({
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
    });
    const imageContainerRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        if (imageRef.current === null) return;
        const { width, height } = getImageSize();
        setVertexBounds({
            left: 0,
            top: 0,
            right: width,
            bottom: height
        });
    }, [imageElementWidth, imageElementHeight]);

    function getImageDimensions(base64String) {
        return new Promise((resolve, reject) => {
            // Create a new image element
            let img = new Image();
            // Set up event listener to execute once image is loaded
            img.onload = function() {
                // Resolve the promise with the width and height
                resolve({ width: this.width, height: this.height });
            };
            img.onerror = reject;
            // Set the source of the image
            img.src = base64String;
        });
    }

    function getImageContainerSize() {
        return {
            width: imageContainerRef.current.offsetWidth,
            height: imageContainerRef.current.offsetHeight
        };
    }

    function getImageSize() {
        return {
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight
        }
    }

    useEffect(() => {
        if(imageContainerRef.current === null) return;
        getImageDimensions(originalImage)
            .then(({ width, height }) => {
                setImageAspectRatio(width / height);
                // Now calculate the width and height of the image element
                // Compare the aspect ratio of the image and the 
                // aspect ratio of the image element
                const { width: containerWidth, height: containerHeight } = getImageContainerSize();
                console.log(containerHeight, containerWidth);
                const containerAspectRatio = containerWidth / containerHeight;
                if (imageAspectRatio > containerAspectRatio) {
                    // The image is wider than the container
                    setImageElementWidth("100%");
                    setImageElementHeight("auto");
                } else {
                    // The image is taller than the container
                    setImageElementWidth("auto");
                    setImageElementHeight("100%");
                }
            });
    }, [originalImage, imageContainerRef.current]);

    const styles = {
        baseContainer: {
            height: "100%",
            width: "100%"
        },
        imageContainer: {
            width: "100%",
            height: "100%",
        },
        image: {
            backgroundImage: `url('${originalImage}')`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: imageElementWidth,
            height: imageElementHeight,
            aspectRatio: imageAspectRatio,
        }
    }

    const drawPolygon = () => {
      const path = [tlPosition, trPosition, brPosition, blPosition].reduce((acc, point, index) => {
        return `${acc} ${point.x},${point.y}`;
      }, "M") + " Z";
      return <path d={path} stroke="blue" strokeWidth="2" fill="#ff000033" />;
    };

    const [tlPosition, setTlPosition] = useState({ x: 0, y: 0 });
    const [trPosition, setTrPosition] = useState({ x: vertexBounds.right, y: 0 });
    const [blPosition, setBlPosition] = useState({ x: 0, y: vertexBounds.bottom });
    const [brPosition, setBrPosition] = useState({ x: vertexBounds.right, y: vertexBounds.bottom });
    function updatePosition(position, setPosition) {
        setPosition(position);
    }

    return (
        <div style={ styles.baseContainer }>
            <div style={ styles.imageContainer } ref={ imageContainerRef } >
                <div style={ styles.image } ref={ imageRef } >
                    <Vertex
                        initialX={ 0 }
                        initialY={ 0 }
                        updatePosition={ (position) => updatePosition(position, setTlPosition) }
                    />
                    <Vertex
                        initialX={ vertexBounds.right }
                        initialY={ 0 }
                        updatePosition={ (position) => updatePosition(position, setTrPosition) }
                    />
                    <Vertex
                        initialX={ 0 }
                        initialY={ vertexBounds.bottom }
                        updatePosition={ (position) => updatePosition(position, setBlPosition) }
                    />
                    <Vertex
                        initialX={ vertexBounds.right }
                        initialY={ vertexBounds.bottom }
                        updatePosition={ (position) => updatePosition(position, setBrPosition) }
                    />
                    <svg style={{ width: '100%', height: '100%', position: 'absolute', 
                        top: '0', left: '0', boxShadow: "0 0 10px 0 rgba(255,255,255,.7)" }}>
                        { drawPolygon() }
                    </svg>
                </div>
            </div>
        </div>
    );
}

export function Vertex({ initialX, initialY, updatePosition, ...props }) {
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: initialX, y: initialY });
    const [origin, setOrigin] = useState({ x: 0, y: 0 });

    useEffect(() => {
        setPosition({ x: initialX, y: initialY });
    } , [initialX, initialY]);
  
    const handleMouseDown = (e) => {
        // Start dragging and set the initial mouse position
        setIsDragging(true);
        setOrigin({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };
  
    const handleMouseMove = (e) => {
        if (isDragging) {
            // Update the position of the element as the mouse moves
            setPosition({
                x: e.clientX - origin.x,
                y: e.clientY - origin.y
            });
        }
    };

    useEffect(() => {
        updatePosition(position);
    }, [isDragging, position, updatePosition]);
  
    const handleMouseUp = () => {
        // Stop dragging
        setIsDragging(false);
    };

    const vertexSize = 25;

    const styles = {
        vertex: {
            width: vertexSize,
            height: vertexSize,
            borderRadius: 500,
            backgroundColor: 'white',
            border: "3px solid red"
        },
        draggable: {
            cursor: 'grab',
            position: 'absolute',
            left: `${position.x-vertexSize/2}px`,
            top: `${position.y-vertexSize/2}px`,
            zIndex: 1000,
            ...props.style // Allow custom styles
        }
    }
  
    return (
        <div
            style={ styles.draggable }
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp} // Consider stopping dragging if the mouse leaves the element
        >
            <div style={ styles.vertex }>

            </div>
        </div>
    );
}