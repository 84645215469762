import styles from './JobPostItem.module.css';

export default function JobPostItem({ title, description }){
    return (
        <div className={ `${styles.jobPostItem}` }>
            <h2 className={ styles.jobPostItemTitle }>{ title }</h2>
            <p className={ styles.jobPostItemDescription }>
                { description }
            </p>
        </div>
    );
}