import Construction from "../../components/Construction/Construction";
import { Title } from "../../components/Content/Content";
import { NotificationList } from "../../components/Notification/Notification";
import { CenterViewer } from "../../components/Viewer/Viewer";

export default function Notifications(){
    return (
        <>
            <CenterViewer>
                <Construction />
                <hr />
                <Title>Notifications</Title>
                <NotificationList />
            </CenterViewer>
        </>
    )
}