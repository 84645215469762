import { useEffect, useState } from "react";
import { useTitle } from "../../lib/hooks";
import styles from "./ShareCard.module.css";
import { useParams } from "react-router-dom";
import { fetchBackend } from "../../lib/util";
import { DeckCardDetails } from "../../components/Deck/DeckCardDetails";
import { CenterViewer } from "../../components/Viewer/Viewer";

export default function ShareCard(){
    useTitle("Receive Card")
    const params = useParams();
    const { CardOid: cardId } = params;

    const [card, setCard] = useState(null);
    useEffect(() => {
        fetchBackend("POST", `card/get/${cardId}`)
        .then(res => res.json())
        .then(data => {
            if(!data.error) {
                setCard(data.card);
            }
        })
    }, [cardId])

    return (
        <>
            <CenterViewer>
                <DeckCardDetails card={card} noShare />
            </CenterViewer>
        </>
    )
}